import React from "react";
import loader from "../../assets/images/nav/loader.gif";

function Loader() {
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-white">
      <img src={loader} alt="loading_animation"></img>
    </div>
  );
}

export default Loader;
