import n1 from "../../../assets/images/news/FI-india-2019-participation.png";
import n2 from "../../../assets/images/news/award-from-nestle.png";
import n3 from "../../../assets/images/news/news1.jpeg";
// image
import news_banner1 from "../../../assets/images/news/news1.jpeg";
import news_banner2 from "../../../assets/images/news/award-from-nestle.jpg";
import n1_1 from "../../../assets/images/news/news06.jpeg";
import n1_2 from "../../../assets/images/news/news05.jpeg";
import n1_3 from "../../../assets/images/news/news07.jpeg";
import n1_4 from "../../../assets/images/news/news200 (1).jpeg";
import n1_5 from "../../../assets/images/news/news300.jpeg";
import n1_6 from "../../../assets/images/news/news400.jpeg";
import n1_7 from "../../../assets/images/news/news600.jpeg";
import n1_8 from "../../../assets/images/news/news700.jpeg";
import n1_9 from "../../../assets/images/news/news85.png";
import n1_10 from "../../../assets/images/news/newsT4.png";
import n1_11 from "../../../assets/images/news/newsY2.png";
import n1_12 from "../../../assets/images/news/newsY5.png";
import n1_13 from "../../../assets/images/news/newsYees.png";
import n1_14 from "../../../assets/images/news/newsYes.png";
import n1_15 from "../../../assets/images/news/news1.jpeg";
//awards 
import aw1 from "../../../assets/images/common/awards-1.png"
import aw2 from "../../../assets/images/common/awards-2.png"
import aw3 from "../../../assets/images/common/awards-3.png"
import aw4 from "../../../assets/images/common/awards-4.png"
import aw5 from "../../../assets/images/common/awards-5.png"
export const news = [
  {
    img: [
      n1_13,
      n1_11,   
      n1_9,
      n1_12,
      n1_14,
      n1_10,
    ],
    link : "connect-with-drytech-innovation-unveiled-at-food-ingredient-exhibitions!",
    time: "Posted on 25 April, 2024 by admin",
    heading: "Connect with Drytech – Innovation unveiled at Food Ingredient Exhibitions!",
    content:
      "Drytech proudly participates in major Food Ingredient exhibitions worldwide, showcasing our innovative products and solutions. We engage with food manufacturers, R&D teams and industry professionals, reflecting our commitment to staying ahead in the industry.",
  },
  {
    img: [aw1
      ,aw2
      ,aw3
      ,aw4
      ,aw5],
    link : "appreciation-from-our-customer-speaks-more-than-our-actions",
    time: "Posted on 25 April, 2024 by admin",
    heading: "Appreciation from our customer speaks more than our actions",
    content:
      "We are honored to have received several prestigious awards from our valued customers, including the Moment of Appreciation Award, Supplier Recognition Award, Certification of Appreciation for Valued Supply Partner and the Golden Leaf Award. These recognitions highlight our commitment to quality, reliability and innovation. They inspire us to continue exceeding expectations and fostering strong partnerships with our customers. We extend our gratitude to all who have supported us on this journey.",
  },
  {
    link : "our-certification",
    img: n1,
    time: "Posted on 25 April, 2024 by admin",
    heading: "Our Certifications",
    content:
      "At Drytech, we are committed to providing you with safe, healthy, and sustainable solutions. Our compliance with stringent certifications and standards underscores this commitment. We are proud to be certified in ISO 9001:2015, ISO 14001:2015, ISO 45001:2018, Kosher, Halal, SEDEX, cGMP, and FSSC 22000, ensuring that our processes meet the highest quality, safety, and sustainability requirements."
  },
];

export const newsDetails = {
  "connect-with-drytech-innovation-unveiled-at-food-ingredient-exhibitions!": {
    title: "Connect with Drytech – Innovation unveiled at Food Ingredient Exhibitions!",
    banner1: news_banner1,
    banner: [
      n1_13,
      n1_11,   
      n1_9,
      n1_12,
      n1_14,
      n1_10,
    ],
    description: [
      "Drytech proudly participates in major Food Ingredient exhibitions worldwide, showcasing our innovative products and solutions. We engage with food manufacturers, R&D teams and industry professionals, reflecting our commitment to staying ahead in the industry.",
      "Discover our evolving product range, including Spray Dried powders, Non-dairy Creamers, and encapsulated flavors, as well as our contract manufacturing and R&D capabilities. We collaborate closely with customers to develop tailored ingredient solutions, helping shape the future of food technology.",
    ],
  },
  "our-certification": {
    title: "Our Certifications",
    banner: n1,
    description: [
      "At Drytech, we are committed to providing you with safe, healthy, and sustainable solutions. Our compliance with stringent certifications and standards underscores this commitment. We are proud to be certified in ISO 9001:2015, ISO 14001:2015, ISO 45001:2018, Kosher, Halal, SEDEX, cGMP, and FSSC 22000, ensuring that our processes meet the highest quality, safety, and sustainability requirements."
    ],
  },
  "appreciation-from-our-customer-speaks-more-than-our-actions": {
    title: "Appreciation from our customer speaks more than our actions",
    banner:    [aw3
      ,aw2
      ,aw1
      ,aw4
      ,aw5],
    description: [
      "We are honored to have received several prestigious awards from our valued customers, including the Moment of Appreciation Award, Supplier Recognition Award, Certification of Appreciation for Valued Supply Partner and the Golden Leaf Award. These recognitions highlight our commitment to quality, reliability and innovation. They inspire us to continue exceeding expectations and fostering strong partnerships with our customers. We extend our gratitude to all who have supported us on this journey.",
    ],
    ul : [
      "Accountability",
      "Customer and Consumer Focus",
      "Creativity & Innovation",
      "Excellence",
      "Speed",
      "Sustainable Relationship",
    ]

  },
};
