export const careers = [
  {
    title: "Account Executive",
    experience: "Experience 2-3 Years",
    listResponsibilities: [
      "Act as the main point of contact for clients.",
      "Build and maintain strong, long-term client relationships.",
      "Understand client needs and provide solutions that align with their business objectives.",
    ],
    eligibility: [
      "Act as the main point of contact for clients.",
      "Build and maintain strong, long-term client relationships.",
      "Understand client needs and provide solutions that align with their business objectives.",
    ],
    dateType: "Walk In Interview From 28TH of Feb - 4TH March 2024",
    timing: "Time - 10 am to 5 pm",
  },
  {
    title: "Branch Head",
    experience: "Experience 5-10 Years",
    listResponsibilities: [
      "Act as the main point of contact for clients.",
      "Build and maintain strong, long-term client relationships.",
      "Understand client needs and provide solutions that align with their business objectives.",
    ],
    eligibility: [
      "Act as the main point of contact for clients.",
      "Build and maintain strong, long-term client relationships.",
      "Understand client needs and provide solutions that align with their business objectives.",
    ],
    dateType: "Walk In Interview From 28TH of Feb - 4TH March 2024",
    timing: "Time - 10 am to 5 pm",
  },
];

export const testimonials = [
  {
    heading: "“ DRYTECH is a great place to work! “",
    content:
      "“ DRYTECH is a great place to work! Here is a list of reasons why: Family friendly, flexible work schedules, suggestions for changes are received openly, easy to implement changes, great co-workers, friendly environment, lots of training opportunities, senior staff seems to really care that employees are happy and motivated, plenty of celebrations and lots of reasons to celebrate, company has strong community presence, company has a good reputation in the community so I am proud to tell people I work with a company that promotes a healthy work environment ”",
    name: "Sr. Marketing Executive",
  },
  {
    heading: "“ Working at Drytech is a great pleasure for me. “",
    content:
      "“Working at Drytech is a great pleasure for me. What I appreciate the most about the company is that, it has given me the opportunity to balance work and life by allowing flexible working hours. I manage to contribute to the company and still have time for my family. Drytech also gives me the opportunity to work in a pleasant multicultural environment with supportive colleagues. The management team is very good in making sure that the suggestions and opinions of employees are included in the company planning process. I am proud to be a part of Drytech.”",
    name: "Sr. Purchase Executive",
  },
  {
    heading: "“ I take a pride in working at Drytech “",
    content:
      "“What makes Drytech stand out is that every single employee has a stake in the company’s success.  We are very team-driven, and we operate in a way that puts collective goals ahead of individual glory. I have been associated with Drytech for more than 5 years now and still it gives me the opportunity to learn new things and manage challenging tasks. Everyone at Drytech is energetic, and fun to work with. We love what we do, and it shows in the strong relationships we have built with our customers too.”",
    name: "Assistant Manager Sales",
  },
  {
    heading:
      "“ Joining Drytech has been one of the best professional decisions of my life. “",
    content:
      "Joining Drytech has been one of the best professional decisions of my life. Immediately after my joining of 15 days, I was involved in major projects of Research and Development Department, which is much like a dream coming true for any scientist. I have the freedom to initiate projects, have open brainstorming sessions with my colleagues. I am also constantly encouraged to outdo myself.”",
    name: "Assistant Manager Applications (R&D)",
  },
];
