import React from "react";
import Heading from "../../common/Heading";

// images
import c1 from "../../../assets/images/our_client/c1.png";
import c2 from "../../../assets/images/our_client/c2.png";
import c3 from "../../../assets/images/our_client/c3.png";
import c4 from "../../../assets/images/our_client/c4.png";
import c5 from "../../../assets/images/our_client/c5.png";
import c6 from "../../../assets/images/our_client/c6.png";
import c7 from "../../../assets/images/our_client/c7.png";
import c8 from "../../../assets/images/our_client/c8.png";
import c9 from "../../../assets/images/our_client/c9.png";
import c10 from "../../../assets/images/our_client/c10.png";
import c11 from "../../../assets/images/our_client/c11.png";
import c12 from "../../../assets/images/our_client/c12.png";
import c13 from "../../../assets/images/our_client/c13.png";
import c14 from "../../../assets/images/our_client/c14.png";
import c15 from "../../../assets/images/our_client/c15.png";
import c16 from "../../../assets/images/our_client/c16.png";
import c17 from "../../../assets/images/our_client/c17.png";
import c18 from "../../../assets/images/our_client/c18.png";
import c19 from "../../../assets/images/our_client/c19.png";
import c21 from "../../../assets/images/our_client/c21.png";
import c22 from "../../../assets/images/our_client/c22.png";
import c23 from "../../../assets/images/our_client/c23.png";
import c24 from "../../../assets/images/our_client/c24.png";
import c25 from "../../../assets/images/our_client/c25.png";
import c26 from "../../../assets/images/our_client/c26.png";
import c27 from "../../../assets/images/our_client/c27.png";
import c28 from "../../../assets/images/our_client/c28.png";
import c29 from "../../../assets/images/our_client/c29.png";
import c30 from "../../../assets/images/our_client/c30.png";
// import c31 from "../../../assets/images/our_client/c31.png";
// import c32 from "../../../assets/images/our_client/c32.png";
// import c33 from "../../../assets/images/our_client/c33.png";

;
function Clients() {
  return (
    <div className="mt-10 p-10 flex flex-col gap-8">
      <Heading
        heading="Every client has a different need."
        helperText="At DRYTECH we understand that no matter what you do or sell – perception is the underlying issue that connects us all. Discover the perception we have changed for our clients from various industries and contact us today to become one of our success stories."
      ></Heading>
      <div className="flex flex-wrap gap-8 pr-5 pl-5 justify-center tab950:justify-start items-start">
        {clients.map((row, i) => (
          <div key={i} className="w-[100px] ">
            <img alt="clients" className="" src={row.img}></img>
          </div>
        ))}
      </div>
    </div>
  );
}
const clients = [
  {
    img: c1,
  },
  {
    img: c2,
  },
  {
    img: c3,
  },
  {
    img: c4,
  },
  {
    img: c5,
  },
  {
    img: c6,
  },
  {
    img: c7,
  },
  {
    img: c8,
  },
  {
    img: c9,
  },
  {
    img: c10,
  },
  {
    img: c11,
  },
  {
    img: c12,
  },
  {
    img: c13,
  },
  {
    img: c14,
  },
  {
    img: c15,
  },
  {
    img: c16,
  },
  {
    img: c17,
  },
  {
    img: c18,
  },
  {
    img: c19,
  },
  {
    img: c21,
  },
  {
    img: c22,
  },
  {
    img: c23,
  },
  {
    img: c24,
  },
  {
    img: c25,
  },
  {
    img: c26,
  },
  {
    img: c27,
  },
  {
    img: c28,
  },
  {
    img: c29,
  },
  {
    img: c30,
  }
];
const client2 = [

]
export default Clients;
