import React from "react";
import Banner from "../common/Banner";

// image
import CardView from "./components/CardView";
import Enquire from "../common/Enquire";
import { useParams } from "react-router-dom";
import { listing } from "./data/listing_data";

function Listing() {
  const { page } = useParams();
  const data = listing[reverseFormat(page)];

  function reverseFormat(input) {
    // Replace hyphens with spaces and capitalize each word
    return input.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }
  return (
    <>
      <Banner banner={data.banner}></Banner>
      <CardView data={data}></CardView>
      <Enquire
        heading={"Subscribe to our newsletter"}
        subheading={
          "Get monthly updates about latest products and offers from Drytech."
        }
        btnText={"Subscribe Now"}
      ></Enquire>
    </>
  );
}

export default Listing;
