import React from "react";
import Heading from "../../common/Heading";
import { productListing } from "../data/data";
import { useNavigate } from "react-router-dom";
import useAOS from "../../../hooks/useAOS";

function CardView({ data }) {
  const navigate = useNavigate();
  useAOS();
  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }
  return (
    <div className="mt-10 pr-5 pl-5 tab950:pr-32 tab950:pl-32">
      <Heading
        classes={{
          helperText: "text-start",
        }}
        heading={data.heading}
      ></Heading>
      <div className="flex flex-col gap-4 w-[90%] tab950:w-[80%] m-auto mt-8">
        {data.description.map((row) => (
          <span className="text-sm text-gray-400">{row}</span>
        ))}
      </div>
      <div className="grid grid-cols-1 tab650:grid-cols-2 gap-8 mt-10">
        {data.products.map((row,i) => (
          <div
            data-aos={((i+1) % 2 !== 0) ? "fade-right" : "fade-left"}
            onClick={() => {
              navigate(`/details/${makeHash(row.name)}`);
            }}
            className="relative max-w-[550px] m-auto flex self-center cursor-pointer flex-col justify-center items-center group"
          >
            {/* Card Image */}
            <img
              src={row.img}
              alt={row.name}
              className="w-full h-full object-cover"
            />

            {/* Blue Overlay */}
            <div
              className={`absolute inset-0 opacity-95 group-hover:opacity-45 transition-opacity duration-300 ${row.bgColor}`}
            ></div>

            {/* Card Text */}
            <span className="absolute text-white text-center font-medium text-3xl z-10">
              {row.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardView;
