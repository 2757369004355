import React from "react";

//image
import banner from "../../../assets/images/about/our-values-bg.png";
import g1 from "../../../assets/images/career/Group-1.png";
import g2 from "../../../assets/images/career/Group-2.png";
import g3 from "../../../assets/images/career/Group.png";
import g4 from "../../../assets/images/career/Group-3.png";
import useAOS from "../../../hooks/useAOS";

function WorkForUs() {
  useAOS();
  return (
    <div
      style={{ backgroundImage: `url('${banner}')` }}
      className="flex flex-col gap-8 items-center p-5 relative mt-10 min-h-[300px]"
    >
      <div data-aos="fade-down" className="flex justify-center mt-8 items-center w-full text-4xl tablets:text-4xl font-semibold text-white">
        <span>Why Work For Us?</span>
      </div>
      <div className="flex flex-wrap gap-10 mt-5 tab650:gap-20 text-center w-full justify-center pr-28 pl-28 items-center">
        {values.map((row,i) => (
          <div
            data-aos={((i+1) % 2 !== 0) ? "fade-right" : "fade-left"}
            key={row.title}
            className="text-white text-center flex flex-col justify-center items-center gap-2"
          >
            <img
              className="min-w-[55px] max-w-[70px]"
              src={row.img}
              alt={row.title}
            />
            <span>{row.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

let values = [
  {
    img: g1,
    title: "Integrity and Fairness",
    content:
      "We respect and value our employees’ contribution towards our success. Integrity and fair business practices has always been on top of our agenda.",
  },
  {
    img: g3,
    title: "Teamwork",
    content:
      "We believe teamwork makes dream work – as teamwork builds trust and trust builds growth.",
  },
  {
    img: g2,
    title: "Excellence",
    content:
      "Achieving excellence is a result of intentions, efforts and smart execution. Working with us is experiencing excellence and excelling through experience.",
  },
  {
    img: g4,
    title: "Reliability and Commitment",
    content:
      "We follow ethical business practices and all our activities are built on reliability and commitment.",
  },
];

export default WorkForUs;
