import React from "react";
import Banner from "../common/Banner";

import aboutBanner from "../../assets/images/about/about-bg.png";

// components
import InfoSection from "../common/InfoSection";
import card_img from "../../assets/images/about/about-innovation-new.jpg";
import card_img2 from "../../assets/images/about/im2.jpg";
import card_img3 from "../../assets/images/about/im3.jpg";
import card_img4 from "../../assets/images/about/im4.jpg";
import Mission from "./components/Mission";
import OurValue from "./components/OurValue";
import ProductCase from "../common/ProductCase";

function About() {
  return (
    <>
      <Banner height={"max-h-[600px]"} banner_title="About DRYTECH" banner={aboutBanner}></Banner>
      <InfoSection
        // counterImg={true}
        counter = {true}
        id={"what-we-do"}
        card={card}
        bigHeading="We simplify nature to add ease to your innovation"
        subHeading="The food habits of the people around the globe have been changing and their preferences are mainly influenced by taste, texture and nourishment provided by food products. It has indeed become an imperative aspect for the processed food manufacturers to understand consumer insights and preferences and develop food products that meets their requirements."
      ></InfoSection>
      <Mission></Mission>
      <OurValue></OurValue>
      <ProductCase
        heading={"Choose your Product from different Applications"}
        helperText={
          "Drytech manufactures and supplies food formulation ingredients to various industries ensuring quality nutrition and excellent taste."
        }
      />
    </>
  );
}

let card = [
  {
    img: [card_img2, card_img,card_img3,card_img4],
    content: `At Drytech, we believe, demand for a safe and healthy food 
            ingredient must be fulfilled for a healthy lifestyle. Our quality
            ingredients lets you indulge into a world of tasty food that blends
            science with passion. We help food manufacturers create products,
            which are not only natural but also rich in nutrients. With our
            state of the art facility and well equipped RD & A center, we have
            been serving the food industry with unique and specialty ingredients
            for several years. Our range of ingredients also help our customers
            to make clean label products and elevate their sensory experience.
            We welcome you on the journey to reform and innovate your food
            products that will delight your customers..`,
  },
];

export default About;
