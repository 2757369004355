import React from "react";
// image
import cs1 from "../../../assets/images/home/cs1.jpg";
import cs2 from "../../../assets/images/home/cs2.jpg";
import cs3 from "../../../assets/images/home/cs3.jpg";
//component
import Slider from "react-slick";
import Heading from "../../common/Heading";
import useAOS from "../../../hooks/useAOS";

function CaseStudies(props) {
  useAOS()
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container pr-8 pl-8 tablets:pr-32 tablets:pl-32 sm:gap-10 gap-5 mt-10 flex flex-col">
      <Heading
        heading={props.heading}
        upperText={props.upperText}
        helperText={props.helperText}
      ></Heading>
      <Slider {...settings}>
        {cards.map((card, i) => (
          <div key={i} className="w-full h-full">
            <div className="flex flex-col-reverse sm:flex-col relative w-full justify-center">
              <div data-aos="fade-right" className="cs-content flex flex-col gap-2 p-5 bg-[#7dd3f7] w-full sm500:max-w-[45%] sm500:absolute z-10">
                <p className="text-[16px] tablets:text-xl font-semibold">
                  {card.title}
                </p>
                <p className="text-[10px] tablets:text-[12px] text-gray-700">
                  {card.content}
                </p>
              </div>
              <div data-aos="fade-left" className="cs-img sm500:w-[80%] w-full  flex sm500:self-end">
                <img src={card.img} className="self-end" alt="_img" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

let cards = [
  {
    img: cs1,
    title: "Flavors of Food blended with Nutrition ",
    content:
      "While adding extra feel, we also take care of preserving the original nutrition of your food products. Our authentic approach allows us to deliver ingredients with richness of nutrition and essential fibers. Our professional team of R&D experts never compromise on the quality and that makes us stand unique in terms of producing and supplying you the best food flavors for your food products.",
  },
  {
    img: cs2,
    title: "The hues of taste",
    content:
      "With flavours, with nutrition and with seasonings, we also offer a quality range of natural coloring ingredients to enhance your food products. Now let the love for food get blend with the hues of taste and bring out the chef that lies with you. Our culinary teams of food experts have an immense passion for food and thus they strive to deliver happiness in the form of food colors.",
  },
  {
    img: cs3,
    title: "The union of art and science",
    content:
      "When it comes to taste, we always aim to exceed consumer expectations. And culinary-inspired, chef-quality solutions are how we achieve this. Our culinary teams are made up of chefs, bakers, and more – all passionate about making the very best foods. Working directly with our insights and product development.",
  },
];

export default CaseStudies;
