import axios from 'axios';
import {LiveAPI,LocalAPI} from '../config';

let API = LiveAPI;
// let API = LocalAPI;

export async function sendMail(data) {
    return await axios.post(`${API}/sendMail`,data,{headers:{
        "Content-Type": "application/json",
    }})
}