import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const useAOS = (options = defaultOptions) => {
  useEffect(() => {
    AOS.init(options);
    // Clean up the AOS instance when component unmounts
    return () => {
      AOS.refresh();
    };
  }, [options]);
};

const defaultOptions = {
  duration: 1300,
  once: true,
  easing: "ease-in-out",
};

export default useAOS;
