import React from "react";
import Banner from "../common/Banner";
import Enquire from "../common/Enquire";
//image
import banner from "../../assets/images/blog/blog-bg.png";
import BlogsList from "./components/BlogsList";


function Blog() {
  return (
    <>
      <Banner banner={banner} banner_title={"Blogs"}></Banner>
      <BlogsList></BlogsList>
      <Enquire
        heading={"Subscribe to our newsletter"}
        subheading={
          "Get monthly updates about latest products and offers from Drytech."
        }
        btnText = {"Subscribe Now"}
      ></Enquire>
    </>
  );
}

export default Blog;
