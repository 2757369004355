import React from "react";
import { news } from "../data/data";
import { useNavigate } from "react-router-dom";
import useAOS from '../../../hooks/useAOS';
import Slider from "react-slick";
function NewsList() {
  let navigate = useNavigate();
  useAOS();
  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }
  return (
    <div className="flex flex-col gap-8 mt-10 pl-8 pr-8 tab950:pr-32 tab950:pl-32">
      {news.map((row) => (
        <div className="flex flex-wrap gap-3">
          <div data-aos="fade-left" className="w-full flex flex-col gap-1">
            <span className="text-xl tablets:text-2xl text-gray-500 font-medium">
              {row.heading}
            </span>
            <span className="text-[11px] ">{row.time}</span>
          </div>
          {typeof row.img === "object" ? (
              <div className="max-h-fit min-h-[100px] min-w-[200px] max-w-[400px] overflow-hidden">
                <Slider {...settings} className="mb-4">
                  {row.img.map((image, index) => (
                    <div key={index} className="max-h-[400px]  w-[400px]">
                      <img
                        src={image}
                        className="w-full object-cover"
                        alt={`slide-${index}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div data-aos="fade-right" className="">
              <img alt="blogs_img" src={row.img} className="max-w-[400px] w-full"></img>
            </div>
            )}
          <div data-aos="fade-left" className=" flex max-w-[500px] flex-col gap-3 text-gray-400">
            <span className="text-[13px]">{row.content}</span>
            <button
              onClick={() => navigate(`/show/news/${makeHash(row.link)}`)}
              className="self-start border p-1 pr-4 pl-4 text-gray-500 border-[#0095DA] text-[12px]"
            >
              Read More
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default NewsList;
