import React from "react";
import Heading from "../../common/Heading";
import { testimonials } from "../data/data";
// image
import banner from "../../../assets/images/about/about-bg-new.jpg";
import Slider from "react-slick";

function LifeAtDryTech() {
  const settings = {
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="mt-10 tab950:pr-32 tab950:pl-32 pr-5 pl-5 flex flex-col gap-10">
      <Heading heading="Life At Dry Tech"></Heading>
      <div className="flex">
        <img
          src={banner}
          className="cursor-pointer filter grayscale-0 hover:grayscale transition duration-300 ease-in-out max-h-[600px] w-full"
          alt="lifeAtDryTech"
        ></img>
      </div>
      {/* // what our employee have to say  */}
      <div className="bg-gray-100 tablets:p-8 p-1 text-center flex flex-col gap-5 pb-10">
        <span className="text-2xl tablets:3xl font-medium">What Our Employees Have To Say</span>
        <Slider className="" {...settings}>
          {testimonials.map((row) => (
            <div className="tablets:pr-10 tablets:pl-10 pr-5 pl-5">
              <div className="flex flex-col gap-5" key={row.name}>
                <span className="text-3xl tablets:text-5xl font-semibold">{row.heading}</span>
                <span className="text-[12px] tablets:text-sm text-gray-500">{row.content}</span>
                <span className="text-sm text-blue-600">{row.name}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default LifeAtDryTech;
