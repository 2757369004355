import React from 'react'

// image 
import banner from '../../assets/images/our_client/clients-bg 1.png'
import Clients from './components/Clients'
import Banner from '../common/Banner'

function OurClient() {
  return (
    <>
    <Banner banner_title="Our Clients" banner={banner}></Banner>
    <Clients></Clients>
    </>
  )
}

export default OurClient