import b1 from "../../../assets/images/blog/millennial-food-preferences.png";
import b2 from "../../../assets/images/blog/the-brass-ring-clean-label.png";
import news_banner1 from "../../../assets/images/news/new-office-shifting.jpg";
import news_banner2 from "../../../assets/images/home/food-tech-02.png";
import news_banner3 from "../../../assets/images/home/food-tech-01.png";

export const blogs = [
  {
    img: b1,
    time: "Posted on 25 April, 2024 by admin",
    heading: "Millennial Food Preferences",
    content:
      "Millennials are the largest living generation with more spending power and a major role to play in determining the pop culture and trends. They are also known as the food generation. When they set trends, businesses quickly shift their attention towards the millennial culture. These young minds have distinct eating habits that the media and researchers analyze and follow.",
  },
  {
    img: b2,
    time: "Posted on 25 April, 2024 by admin",
    heading: "The Brass Ring Clean Label",
    content:
      "The Clean Label trend is sweeping through the marketplace. Breakfast or late-night indulgences, sweets or savories, every brand, be it big or small, is opting to clean up their ingredient lists. Clean Label means a new product out of very few recognizable ingredients that consumers might use often at home. It promotes the prevention of artificial ingredients or synthetic chemicals.",
  },
];

export const blogsDetails = {
  "the-brass-ring-clean-label": {
    title: "The Brass Ring Clean Label",
    banner: news_banner3,
    description: [
      "The Clean Label trend is sweeping through the marketplace. Breakfast or late-night indulgences, sweets or savories, every brand, be it big or small, is opting to clean up their ingredient lists. Clean Label means a new product out of very few recognizable ingredients that consumers might use often at home. It promotes the prevention of artificial ingredients or synthetic chemicals.",
    ],
    link: "appreciation-from-our-customer-speaks-more-than-our-actions",
    link2: "",
  },
  "millennial-food-preferences": {
    title: "Millennial Food Preferences",
    banner: news_banner2,
    description: [
      "Millennials are the largest living generation with more spending power and a major role to play in determining the pop culture and trends. They are also known as the food generation. When they set trends, businesses quickly shift their attention towards the millennial culture. These young minds have distinct eating habits that the media and researchers analyze and follow.",
    ],
    link: "appreciation-from-our-customer-speaks-more-than-our-actions",
    link2: "",
  },
};
