import React from "react";
import { NavLink } from "react-router-dom";
import useAOS from "../../hooks/useAOS";

function Footer() {
  useAOS();
  return (
    <footer className="footer mt-10">
      <div className="foot-links flex bg-gray-100 p-10 pl-8 pr-6 gap-6 sm:justify-center justify-normal flex-wrap">
        <div data-aos="fade-right" className="flex flex-col gap-4 text-sm max-w-96 ">
          <span className="font-medium  ">About</span>
          <span className="text-gray-600 text-sm">
            Drytech begin its innovation 1992 with a vision to serve the Food
            and Nutraceutical industry. Today it is renowned and appreciated for
            manufacturing, supplying and trading the most authentic, and quality
            range of food and nutraceutical ingredients to its customers across
            the globe.
          </span>
        </div>
        <div data-aos="fade-right" className="flex flex-col gap-4 text-sm min-w-40">
          <span className="font-medium ">Products</span>
          <ul className="flex flex-col gap-2 text-gray-600 text-sm">
            <li>
              <NavLink to={'/details/fruit-powder'}>Fruit Powder</NavLink>
            </li>
            <li>
              <NavLink to={'/listing/gum-arabic'}>Gum Arabic</NavLink>
            </li>
            <li>
              <NavLink to={'/listing/nutrition'}>Nutrition</NavLink>
            </li>
            <li>
              <NavLink to={'/listing/seasoning'}>Seasoning</NavLink>
            </li>
            <li>
              <NavLink to={'/listing/creamer'}>Creamer</NavLink>
            </li>
            <li>
              <NavLink to={'/listing/natural-color'}>Natural Color</NavLink>
            </li>
            <li>
              <NavLink to={'/details/instant-fat-filled-powder'}>Instant Fat Filled Powder</NavLink>
            </li>
          </ul>
        </div>
        <div data-aos="fade-left" className="flex flex-col gap-4 text-sm min-w-52">
          <span className="font-medium ">Important Links</span>
          <ul className="flex flex-col gap-2 text-gray-600 text-sm">
            <li><NavLink to={'/milestones'}>Milestones</NavLink></li>
            <li><NavLink to={'/our-client'}>Clientele</NavLink></li>
            <li><NavLink to={'/news'}>News & Events</NavLink></li>
            <li><NavLink to={'/download'}>Download</NavLink></li>
            <li><NavLink to={'/blog'}>Blog</NavLink></li>
            <li><NavLink to={'/contact'}>Contact</NavLink></li>
            <li><NavLink to={'/sustainability'}>Sustainablity</NavLink></li>
          </ul>
        </div>
        <div data-aos="fade-left" className="flex flex-col gap-4 text-sm max-w-64">
          <span className="font-medium ">Contact</span>
          <span className="text-gray-600 text-sm">
            Drytech Processes (I) Pvt LtdT/13-14, 3rd Floor,Pinnacle Business
            Park,Mahakali Caves Rd, Shanti Nagar,Andheri East,
            Mumbai,Maharashtra 400093
          </span>
        </div>
      </div>
      <div className="foot-copy text-sm p-6 flex justify-evenly">
        <p >© {new Date().getFullYear()} Drytech Processes </p>
        <a target="_blank" href="https://www.bitwittech.com/" rel="noreferrer">
          <p> Made with <span className="text-red-500">&#9829;</span> by Bitwit Technologies</p>
          </a>
      </div>
    </footer>
 );
}

export default Footer;
