import React from "react";
import useAOS from "../../../hooks/useAOS";

function Map() {
  useAOS();
  return (
    <div className="mt-10" data-aos={"fade-down"}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732276.8902557436!2d73.92106037278116!3d24.002780341579253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd5aec6436d775d%3A0xee6a907823c8badf!2sDrytech%20Processes%20(I)%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1727158459927!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
