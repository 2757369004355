import React from "react";
import banner from "../../assets/images/news/new-office-shifting.jpg";
import Slider from "react-slick";

function Right(props) {
  let { data } = props;
  console.log(props);
  return (
    <div className="flex flex-col gap-4 min-w-[70%] justify-center items-center tablets:items-start max-w-full p-5">
      <span className="text-2xl font-medium ">{data.title}</span>
      <hr></hr>
      {typeof data.banner === "object" ? (
        <div className=" max-h-fit min-h-[200px] min-w-[100px] max-w-[550px] m-auto overflow-hidden">
          <Slider {...settings} className="mb-4">
            {data.banner.map((image, index) => (
                <img className="max-h-[400px] min-h-[200px] w-full object-cover" src={image} alt="banner"></img>
            ))}
          </Slider>
        </div>
      ) : (
        <img className="min-w-[350px] m-auto " src={data.banner} alt="banner"></img>
      )}

      {/* descriptions */}
      <div className="flex flex-col pr-10 pl-10 tablets:p-0 text-gray-500 text-[12px] gap-4">
        {data?.description &&
          data?.description.map((content, i) => <span key={i}>{content}</span>)}
      </div>
      <hr></hr>
      {/* link */}
      <div className="flex flex-wrap gap-5 justify-between text-blue-300  text-[10px]">
        <a href="/" className="hover:text-orange-300">
          Our Certifications
        </a>
        <a href="/" className="hover:text-orange-300">
          The Specialty Ingredients has a new home now!!
        </a>
      </div>
      {/* form  */}
      {/* <Form></Form> */}
    </div>
  );
}

const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
function Form() {
  return (
    <div className="flex flex-col gap-3 border shadow-md p-5 w-full">
      <div className="flex flex-col gap-1">
        <span className="text-[15px] font-normal">Leave a Reply</span>
        <span className="text-[10px] text-gray-500">
          Your email address will not be published. Required fields are marked
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-[12px] font-medium" for="comment">
            Comment <span className="text-red-600">&#42;</span>
          </label>
          <textarea
            className="border resize-none"
            rows={4}
            id="comment"
            required
          ></textarea>
        </div>
        <div className="flex flex-wrap gap-1">
          <div className="flex flex-col flex-1">
            <label className="text-[12px] font-medium" for="name">
              Name <span className="text-red-600">&#42;</span>
            </label>
            <input required className="border p-1" id="name" name="name" />
          </div>
          <div className="flex flex-col flex-1">
            <label className="text-[12px] font-medium" for="email">
              Email <span className="text-red-600">&#42;</span>
            </label>
            <input
              required
              className="border p-1"
              type="email"
              id="email"
            ></input>
          </div>
          <div className="flex flex-col flex-1">
            <label className="text-[12px] font-medium">Website </label>
            <input className="border p-1" type="url"></input>
          </div>
        </div>
        <div className="flex gap-2">
          <input type="checkbox" id="check"></input>
          <label className="text-[12px] text-gray-600">
            Save my name, email, and website in this browser for the next time I
            comment.
          </label>
        </div>
        <button className=" text-[12px] border-black hover:border-[#0095DA] hover:bg-[#0095DA] transition-all hover:text-white duration-500 self-start p-2 border font-sans text-gray-500">
          Post Comment
        </button>
      </div>
    </div>
  );
}

export default Right;
