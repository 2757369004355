import React from "react";

// component
import Slider from "./components/Slider";
import ProductCase from "../common/ProductCase";
import View from "./components/View";
import BrandVideo from "./components/BrandVideo";
import CaseStudies from "./components/CaseStudies";
import News from "./components/News";
import Events from "./components/Events";
import useAOS from "../../hooks/useAOS";

function Home() {
  return (
    <>
      <Slider />
      <ProductCase
        upperText={"PARTNER INDUSTRIES"}
        helperText={
          "Drytech manufactures and supplies food formulation ingredients to various industries ensuring quality nutrition and excellent taste."
        }
        heading={"Multi-layered Application"}
      />
      <View />
      <BrandVideo></BrandVideo>
      <CaseStudies
       upperText={"Innovation"}
       heading={"Case studies"}
       helperText={
         "Drytech manufactures and supplies food formulation ingredients to various industries ensuring quality nutrition and excellent taste."
       }
      ></CaseStudies>
      <News></News>
      <Events></Events>
    </>
  );
}

export default Home;
