import React from "react";

//image
import banner from "../../../assets/images/about/our-values-bg.png";
import ov1 from "../../../assets/images/about/ov1.png";
import ov2 from "../../../assets/images/about/ov2.png";
import ov3 from "../../../assets/images/about/ov3.png";
import ov4 from "../../../assets/images/about/ov4.png";
import ov5 from "../../../assets/images/about/ov5.png";
import ov6 from "../../../assets/images/about/ov6.png";

function OurValue() {
  return (
    <div
      style={{ backgroundImage: `url('${banner}')` }}
      className="flex flex-col gap-8 items-center p-5 relative mt-10 min-h-[300px]"
    >
      <div className="flex justify-center mt-8 items-center w-full text-4xl tablets:text-5xl font-semibold text-white">
        <span>Our Value</span>
      </div>
      <div className="flex flex-wrap gap-10 mt-5 tab650:gap-20 text-center w-full justify-center pr-28 pl-28 items-center">
        {values.map((row) => (
          <div
            key={row.title}
            className="text-white text-center flex flex-col justify-center items-center gap-2"
          >
            <img
              className="min-w-[50px] max-w-[70px]"
              src={row.img}
              alt={row.title}
            />
            <span>{row.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

let values = [
  {
    img: ov2,
    title: "Customer Service",
  },
  {
    img: ov1,
    title: "Adventurous",
  },
  {
    img: ov3,
    title: "Enterprising",
  },
  {
    img: ov6,
    title: "Respect",
  },
  {
    img: ov5,
    title: "Perseverance",
  },
  {
    img: ov4,
    title: "Innovation",
  },
];

export default OurValue;
