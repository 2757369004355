import React from "react";
import Banner from "../common/Banner";

// images
import banner from "../../assets/images/career/career-bg 1.png";
import InfoSection from "../common/InfoSection";
import WorkForUs from "./components/WorkForUs";
import WhyDryTech from "../common/WhyDryTech";
import LifeAtDryTech from "./components/LifeAtDryTech";
import Openings from './components/Openings'
function Career() {
  // careers@drytechindia.com
  return (
    <>
      <Banner banner={banner} banner_title="Working with DRYTECH" />
      <InfoSection
        id="working-with-drytech"
        subheading_style="text-center com1100:pr-28 com1100:pl-28 pr-8 pl-8"
        p1="A tradition of innovation, excellence and collaboration."
        p3="Our goal is to support food manufacturers internationally. We have been able to achieve this because of our team and their dedication."
        mail={true}
        subHeading="At Drytech, we always look for innovative ways to further drive our business developments and provide innovative solution to our customers. Employees are valued assets of the organization, and since our inception we strive and provide healthy working environment to all our employees."
        bigHeading={"Streamlining your innovations naturally"}
      ></InfoSection>
      <WorkForUs></WorkForUs>
      {/* <Openings id='career-opportunities'></Openings> */}
      <LifeAtDryTech></LifeAtDryTech>
      <WhyDryTech id="why-drytech"></WhyDryTech>
    </>
  );
}

export default Career;
