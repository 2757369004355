import React from "react";

function Banner(props) {
  return (
    <div className={"banner-container relative flex flex-col justify-center items-center " + props.classes}>
      {props.banner_title && (
        <span className="absolute text-4xl font-medium text-center w-full text-white">
          {props.banner_title}
        </span>
      )}
      <img src={props.banner} alt="banner_dry_tech" className={`w-full min-h-[150px] object-center  ${ props.height ? props.height : "max-h-[400px]"}`}></img>
    </div>
  );
}

export default Banner;
