import React, { useEffect, useState } from "react";
import Left from "./Left";
import Right from "./Right";
import { useParams } from "react-router-dom";
import { newsDetails } from "../news_events/data/data";
import { blogsDetails } from "../blog/data/data";

function Show() {
  let { title, page } = useParams();
  let [data, setData] = useState(null);


  useEffect(() => {
    console.log(">>>",newsDetails[title],title)
    if (page === "blog") setData(blogsDetails[title] || {});
    else setData(newsDetails[title] || {});
  }, [title, page]);

  return (
    <div className="flex tab950:pr-20 tab950:pl-20 com1100:pr-24 com1100:pl-24 mt-10">
      {data && <Right data={data}></Right>}
      {data && <Left data={data}></Left>}
    </div>
  );
}

export default Show;
