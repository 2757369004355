import _1 from "../../../assets/images/about/timeline/the-beginning.png";
import _2 from "../../../assets/images/about/timeline/first-product-launch.png";
import _3 from "../../../assets/images/about/timeline/started-manufacturing.png";
import _4 from "../../../assets/images/about/timeline/new-undertakings.png";
import _5 from "../../../assets/images/about/timeline/laboratory.png";
import _6 from "../../../assets/images/about/timeline/herbaceuticals.png";
import _7 from "../../../assets/images/about/timeline/beverage-whitener.png";
import _8 from "../../../assets/images/about/timeline/launched-UHT.png";
import _9 from "../../../assets/images/about/timeline/new-plant.png";
import _10 from "../../../assets/images/about/timeline/microencapsulation.png";
import _11 from "../../../assets/images/about/timeline/pharma-industry.png";
import _12 from "../../../assets/images/about/timeline/nozzle-type-spray-dryer.png";
import _13 from "../../../assets/images/about/timeline/pure-tomato-powder.png";
import _14 from "../../../assets/images/about/timeline/milk-bottle.png";
import _15 from "../../../assets/images/about/timeline/new-manufacturing-unit.png";
// import _1 from '../../../assets/images/about/timeline/'
// import _1 from '../../../assets/images/about/timeline/'

export const progress = [
  {
    img: _1,
    content: "Drytech laid its foundation",
    title: "Inception",
    pos: "up",
    size: "big",
    year: 1992,
  },
  {
    img: _1,
    content: "We Produced our first powder granules",
    title: "The very first Production",
    pos: "up",
    size: "small",
    year: 1994,
  },
  {
    img: _10,
    content:
      "We exported our products and associated with the Pharmaceutical industry for the first time.",
    title: "Started Exports and Associations",
    pos: "down",
    size: "small",
    year: 1995,
  },
  {
    img: _11,
    content:
      "We established new technology for micro- encapsulation for flavors, fragrances and vitamins",
    title: "Introduced new Technology",
    pos: "down",
    size: "big",
    year: 1996,
  },
  {
    img: _6,
    content: "Drytech started manufacturing fruits and vegetable powders too",
    title: "Vegetable & Fruit Powders",
    pos: "up",
    size: "small",
    year: 1997,
  },
  {
    img: _7,
    content: "We stepped into the world of manufacturing powdered nutrition",
    title: "Nutrition Powder",
    pos: "up",
    size: "big",
    year: 2000,
  },
  {
    img: _12,
    content:
      "We successfully became a ‘one stop solution’ for snacks and seasoning industry with a new range of seasoning ingredients",
    title: "One-step Solution",
    pos: "down",
    size: "small",
    year: 2003,
  },
  {
    img: _13,
    content:
      "Drytech managed to construct an automatic fruit and vegetable juice plant",
    title: "Upgraded on automation",
    pos: "down",
    size: "big",
    year: 2004,
  },
  {
    title: "Q.A art & Micro- Biological Laboratory",
    content:
      "A new state of the art Q.A. & Micro-Biological laboratory was set up",
    img: _3,
    pos: "up",
    size: "big",
    year: 2005,
  },
  {
    img: _8,
    content:
      "We experienced and realized the benefits of Ayurveda and manufactured herbaceuticals too.",
    title: "Herbaceuticals products",
    pos: "up",
    size: "small",
    year: 2006,
  },
  {
    img: _14,
    content:
      "Our team work enables us to become the first Indian manufacture of Pure Tomato Powder",
    title: "Pure Tomato Powder",
    pos: "down",
    size: "small",
    year: 2010,
  },
  {
    img: _15,
    content:
      "Taking a new challenge in the era of Fat Powder, we also acquired major leads in infant nutrition.",
    title: "Infant Nutrition Powder",
    pos: "down",
    size: "big",
    year: 2011,
  },
  {
    img: _4,
    content:
      "Drytech became pioneers in manufacturing Fat Powders with fat loading up to 85%",
    title: "Fat Powders",
    pos: "up",
    size: "small",
    year: 2012,
  },
  {
    img: _9,
    content:
      "We adjacently initiated research work on beverage whitener to replace milk",
    title: "Initiated research on Beverage Whitener",
    pos: "up",
    size: "big",
    year: 2013,
  },
  // down

  {
    img: _15,
    content:
      "Successfully replaced milk with beverage whitener and installed a multi-stage spray dryer to manufacture non-dairy creamers.",
    title: "Replaced milk with Beverage Whitener",
    pos: "down",
    size: "small",
    year: 2015,
  },
  {
    img: _13,
    content: "We successfully launched UHT liquid beverage line too",
    title: "Launched UHT",
    pos: "down",
    size: "big",
    year: 2017,
  },
  {
    img: _9,
    content:
      "Expansion of unit II with production capacity of 20,000 MT of Fat Powders which can be further expanded to 60,000MT.",
    title: "Expansion",
    pos: "up",
    size: "big",
    year: 2019,
  },
  {
    img: _9,
    content:
      "Capacity for 3 dryers with production capacity of 60000 MT.",
    title: "Completed Expansion",
    pos: "up",
    size: "big",
    year: 2022,
  },
  {
    img: _5,
    content:
      "Current Operational capacity of 100000 MT.",
    title: "Lunched high Fat NDC",
    pos: "down",
    size: "small",
    year: 2024,
  },
];
