import tomato from '../../../assets/images/products/listing/tomato-powder.jpg';
import tamarind from '../../../assets/images/products/listing/tamarind-powder.jpg';
import beetroot from '../../../assets/images/products/listing/beetroot-powder.jpg';
import fat from '../../../assets/images/products/listing/fat-powder.jpg';
import amla from '../../../assets/images/products/listing/amla-powder.jpg';
import HVP from '../../../assets/images/products/listing/HVP.jpg';
import beverage from '../../../assets/images/products/listing/beverage-whitener.jpg';
import non from '../../../assets/images/products/listing/non-dairy-creamer.jpg';
import caseinates from '../../../assets/images/products/listing/caseinates.jpg';
import cheese from '../../../assets/images/products/listing/cheese-powder.jpg';
import diesol from '../../../assets/images/products/listing/diesol-soluble-dietary-fibre.jpg';
import gum from '../../../assets/images/products/listing/gum-arabic.jpg';
import fruit from '../../../assets/images/products/listing/fruit-powders.jpg';
import lime from '../../../assets/images/products/listing/lime.jpg';
import caramel from '../../../assets/images/products/listing/caramel-color.jpg';

// banner 
import bakery_bg from '../../../assets/images/products/listing/bakery-bg.png';
import beverage_bg from '../../../assets/images/products/listing/beverage-bg.png';
import confectioneries_bg from '../../../assets/images/products/listing/confectioneries-bg.png';
import convenience_bg from '../../../assets/images/products/listing/convenience-food-bg.png';
import creamer_bg from '../../../assets/images/products/listing/creamer-bg.png';
import snack_bg from '../../../assets/images/products/listing/snack-and-savoury-bg.png';
import seasoning_bg from '../../../assets/images/products/listing/seasoning-ingredients-bg.png';
import nutrition_bg from '../../../assets/images/products/listing/nutrition-bg.png';
import nutraceutical_bg from '../../../assets/images/products/listing/nutraceutical-bg.png';
import natural_bg from '../../../assets/images/products/listing/natural-color-bg.png';
import health_bg from '../../../assets/images/products/listing/health-food-bg.png';
import frozen_bg from '../../../assets/images/products/listing/frozen-desserts-bg.png';
import fruit_bg from '../../../assets/images/products/listing/fruit-and-vegetables-bg.png';
import dairy_bg from '../../../assets/images/products/listing/dairy-bg.png';


const bg = {
  yellow: "bg-[#F9C27699]",
  red: "bg-[#E0D89999]",
  blue: "bg-[#7CBBE599]",
  green: "bg-[#85CCA199]",
  pink: "bg-[#F6ADB999]",
};
export const listing = {
  "Fruit & Vegetables": {
    banner: fruit_bg,
    heading: "Fruit & Vegetables",
    description: [
      "A step towards innovation by maintaining natural essence through Spray Dried Fruit and Vegetable Powders. At Drytech, we simplify food habits by producing high-quality food ingredients that are both rich in taste and nutrients. Our Fruit and vegetable powders plays a vital role in flavoring and enhancing your food products by giving them a longer shelf life, enriched with essential vitamins and minerals.",
      "We produce Spray Dried Fruit and Vegetable Powders from high quality raw materials retaining essential nutrients. We retain authenticity of fruits and vegetables and let you experience food with its natural taste and purity.",
    ],
    products: [
      {
        img : tomato,
        link: "",
        name: "Tomato Powder",
        bgColor: bg.red,
      },
      {
        img : tamarind,
        link: "",
        name: "Tamarind Powder",
        bgColor: bg.blue,
      },
      {
        img : fruit,
        link: "",
        name: "Fruit Powder",
        bgColor: bg.green,
      },
      {
        img : amla ,
        link: "",
        name: "Amla Powder",
        bgColor: bg.pink,
      },
    ],
  },
  "Gum Arabic": {
    banner: confectioneries_bg,
    heading: "Gum Arabic",
    description: [
      "Gum Arabic, also called as acacia gum or acacia powder made from the natural hardened sap of two types of wild Acacia trees- Acacia senegal and Vachellia. Gum Arabic helps stabilize food products as it is natural emulsifying agent. It is a source of prebiotics and soluble fiber. Being recognized as the safest dietary fibre by FDA, Gum Arabic is the most recommended food supplement.",
      "At Drytech, we produce Gum Arabic food ingredients in the forms of water-soluble spray dried powders that can be used in the industry segments like Confectionery, Beverages, Bakery, Flavor, Pharmaceutical, Snacks & Savory, Dairy and Nutraceutical for making gum paste and thickening and emulsifying salad dressings & flavour oils.",
    ],
    products: [
      {
        img : gum,
        link: "",
        name: "Spray Argum",
        bgColor: bg.red,
      },
      {
        img : diesol,
        link: "",
        name: "Diesol Soluble Dietary Fiber",
        bgColor: bg.blue,
      },
    ],
  },
  "Nutrition": {
    banner: nutrition_bg,
    heading: "Nutrition",
    description: ["Drytech is not only limited to manufacturing food ingredients that enhances indulgence experience but also engaged in producing rich nutrition ingredients. Our range of nutritional ingredients are available in the form of spray dried powders that can be used in health and nourishing food supplements. Our nutritional ingredients are made authentically from safe and highly selective grades of raw materials that help the daily nutrition requirements of an individual."],
    products: [
      {
        img : fat,
        link: "",
        name: "Fat Powder",
        bgColor : bg.yellow,
      },
      {
        img : caseinates,
        link: "",
        name: "Caseinate",
        bgColor : bg.blue,
      },
    ],
  },
  "Seasoning": {
    banner: seasoning_bg,
    heading: "Seasoning",
    description: [
        "We offer some of the premium spray dried seasoning ingredients like tomato powder, tamarind powder, cheese powder and Hydrolyzed vegetable protein among various others. Our seasoning ingredients significantly contributes to the wide array of taste profiles in your seasoning blends and gives your product a distinct identity.",
        "We ensure that the authenticity of taste of vegetables and its nutritional values are retained in our seasoning ingredients, so that those are in turn a part of your seasoning blends and the snack products made from it. We are proud to be associated with some of the leading snack brands for several years now. Reach out to us and explore our specialty ingredients.",
    ],
    products: [
      {
        img : tomato,
        link: "",
        name: "Tomato Powder",
        bgColor : bg.red,
      },
      {
        img : caseinates,
        link: "",
        name: "Caseinate",
        bgColor : bg.blue,
      },
      {
        img : HVP,
        link: "",
        name: "Hydrolyzed Vegetable Protein",
        bgColor : bg.green,
      },
      {
        img : tamarind,
        link: "",
        name: "Tamarind Powder",
        bgColor : bg.pink,
      },
      {
        img : lime,
        link: "",
        name: "Lime Powder",
        bgColor : bg.yellow,
      },
    ],
  },
  "Creamer": {
    banner: creamer_bg,
    heading: "Creamer",
    description: [
        "A hot cup of coffee evokes our senses and brings delight to our taste buds. To make your coffee experience memorable, Drytech offers wide range of creamers which imparts smooth, rich mouth feel and improved stability.",
        "Our everlasting commitment to quality and product innovation are the reasons, we are always most preferred supplier partner with all major customers for creamers across the industry. Our creamers are instantly cold and hot water soluble, offer enhanced mouth feel, improved stability and are cost effective. These product uniqueness has helped us to connect with major creamer customers locally as well as globally.",
        "In addition to the beverage applications, these creamers can also be used in the premixes of Bakery and Ice creams.",
    ],
    products: [
      {
        img : non,
        link: "",
        name: "Non Dairy Creamer",
        bgColor : bg.red,
      },
      {
        img : beverage,
        link: "",
        name: "Beverage Whitener",
        bgColor : bg.blue,
      },
    ],
  },
  "Natural Color": {
    banner: natural_bg,
    heading: "Natural Color",
    description: [
        "For the best visual appeal of your food products, rely on us, as we offer most authentic and vibrant natural food colors, extracted from fresh raw materials.",
        "With increasing health consciousness and ever growing demand for clean label products, we offer natural food colors which are visually appealing, vibrant and completely safe in the food product. Our precision in process control and best quality raw material ensures we offer natural foods colors that are of premium quality. Our expert research and development team has created a range of “clean label” friendly natural color which will make the food products safe and tempting for consumption",
    ],
    products: [
      {
        img : beetroot ,
        link: "",
        name: "Beetroot Powder",
        bgColor : bg.red,
      },
      {
        img : caramel,
        link: "",
        name: "Caramel Color",
        bgColor : bg.blue,
      },
    ],
  },
  "Snack & Savory": {
    banner: snack_bg,
    heading: "Snack & Savory",
    description: ["Tempting food with irresistible flavor having balanced nutrition makes a perfect snack for you. Indulge into goodness of unmatched snacks with Drytech’s specialty ingredients, especially meant for savory application. Our specialty ingredients contributes to taste and nutrition in lot of tangy food products like instant soups, noodles, chips, gravies etc."],
    products: [
      {
        img : tomato,
        link: "",
        name: "Tomato Powder",
        bgColor : bg.red,
      },
      {
        img : tamarind,
        link: "",
        name: "Tamarind Powder",
        bgColor : bg.blue,
      },
      {
        img : cheese,
        link: "",
        name: "Cheese Powder",
        bgColor : bg.green,
      },
      {
        img : HVP,
        link: "",
        name: "Hydrolyzed Vegetable Protein",
        bgColor : bg.pink,
      },
    ],
  },
  "Confectioneries": {
    banner: confectioneries_bg,
    heading: "Confectioneries",
    description: ["With evolving trends and changing customer preferences, we innovate and customize our food ingredients and help you simplify your innovation. We have range of ingredients for confectionery segment that lets you reformulate and enhance taste, texture and indulgence of your products and at the same time offering added advantage of cost optimization. Come and explore a world of ingredients for confections with us."],
    products: [
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg.red,
      },
      {
        img : gum,
        link: "",
        name: "Spray Aragum",
        bgColor : bg.blue,
      },
    ],
  },
  "Beverage": {
    banner: beverage_bg,
    heading: "Beverage",
    description: ["Explore our unique offerings for beverages ranging from natural fruit powders, non-dairy creamers,natural colors across products ranging from powder premixes, Tea/Coffee premixes, instant hot and cold beverage mixes, milkshakes etc. Our product innovation and unmatched quality allows our customers in their quest to develop unique formulation and position their products differently."],
    products: [
      {
        img : gum ,
        link: "",
        name: "Spray Aragum",
        bgColor : bg.red,
      },
      {
        img : fruit,
        link: "",
        name: "Fruit Powder",
        bgColor : bg.blue,
      },
      {
        img : non,
        link: "",
        name: "Non Dairy Creamer",
        bgColor : bg.green,
      },
      {
        img : beverage,
        link: "",
        name: "Beverage Whitener",
        bgColor : bg.pink,
      },
      {
        img : fat,
        link: "",
        name: "INSTANT FAT FILLED POWDER",
        bgColor : bg.yellow,
      },
    ],
  },
  "Nutraceutical": {
    banner: nutraceutical_bg,
    heading: "Nutraceutical",
    description: ["While pampering your taste buds, we also offer various ingredients in nutrition segment. Our ingredients provide macro nutrition which are essential in our daily life. Fat Powder from various fat source immensely help to manage the essential fatty acid ratio in nutritional products, fulfilling desired energy requirements. Our natural fruit powders also help to boost Vitamin C in nutritional supplements and help to build immunity."],
    products: [
      {
        img : fat,
        link: "",
        name: "Nutraceutical",
        bgColor : bg,
      },
      {
        img : non,
        link: "",
        name: "Non Dairy Creamer",
        bgColor : bg,
      },
    ],
  },
  "Convenience Food": {
    banner: convenience_bg,
    heading: "Convenience Food",
    description: [
        "We all live hustle-bustle life that drives a need for a quick, tasty and healthy food very often these days. We understand the core of this category and its customers, which drives us to create and offer ingredients to enhance the taste and nutrition value of your food.",
        "Explore our product offerings like fruit and vegetable powders, Fat Powder, cheese powder, natural colors for ready to cook/ ready to eat products like instant soup, instant food premixes, instant beverage premixes and dessert premixes. .",
    ],
    products: [
      {
        img : fat,
        link: "",
        name: "Fat Powder",
        bgColor : bg,
      },
      {
        img : fruit,
        link: "",
        name: "Fruit Powder",
        bgColor : bg,
      },
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg,
      },
      {
        img : tomato,
        link: "",
        name: "Tomato Powder",
        bgColor : bg,
      },
    ],
  },
  "Bakery": {
    banner: bakery_bg,
    heading: "Bakery",
    description: ["Bakery is a dynamic and evolving category because of the quest for continuous product improvement, new product launches and a number of products this category consists of. Drytech offers unique bakery ingredients that enables bakers to improve taste, texture and shelf life of the product at the same time offering added advantage of cost optimization. Reach out to us and explore our unique ingredients for bakery products."],
    products: [
      {
        img : gum,
        link: "",
        name: "Spray Argum",
        bgColor : bg.red,
      },
      {
        img : cheese ,
        link: "",
        name: "Cheese Powder",
        bgColor : bg.blue,
      },
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg.green,
      },
      {
        img : fat,
        link: "",
        name: "Fat Powder",
        bgColor : bg.pink,
      },
    ],
  },
  "Dairy": {
    banner: dairy_bg,
    heading: "Dairy",
    description: ["Dairy companies are using their capabilities and scale to grow beyond their core products and newer markets. Hence although dairy is staple, it is still a dynamic category. To catch on to the top dairy trends like protein punch, fat filled products, indulgence and convenience please reach out to us to check on our offerings for Dairy."],
    products: [
      {
        img :gum,
        link: "",
        name: "Spray Aragum",
        bgColor : bg.red,
      },
      {
        img : caseinates,
        link: "",
        name: "Caseinate",
        bgColor : bg.blue,
      },
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg.green,
      },
    ],
  },
  "Health Food": {
    banner: health_bg,
    heading: "Health Food",
    description: ["Healthy food is not a choice but a way of living during current times. Due to drastic change in life style and eating patterns, consumers are increasingly getting cautious of their food which should be tasty, nourishing as well as fulfilling. We at Drytech help you achieve these goals so that you can delight your customers with healthy food products and assist them on their journey to healthy snacking."],
    products: [
      {
        img : diesol,
        link: "",
        name: "Diesol Soluble Dietary Fiber",
        bgColor : bg.red,
      },
      {
        img : caseinates,
        link: "",
        name: "Caseinate",
        bgColor : bg.blue,
      },
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg.green,
      },
    ],
  },
  "Frozen Desserts": {
    banner: frozen_bg,
    heading: "Frozen Desserts",
    description: ["Frozen desserts appeals to everyone right from a child to an elderly person.Enjoying a good flavorful dessert is indeed a guilty pleasure we all indulge in. We, at Drytech, help you achieve unique frozen dessert experience. Our ingredients help you improve the texture as well as taste of the frozen desserts. Please get in touch with us and explore our frozen dessert ingredients like Fat Powder, Non Dairy Creamers and Natural colors."],
    products: [
      {
        img : fat,
        link: "",
        name: "Fat Powder",
        bgColor : bg.red,
      },
      {
        img : non,
        link: "",
        name: "Non Dairy Creamer",
        bgColor : bg.blue,
      },
      {
        img : beetroot,
        link: "",
        name: "Natural Color",
        bgColor : bg.green,
      },
    ],
  }
};
