import React from "react";
import { whydrytech } from "../data/data";
import didYou from "../../assets/images/products/details/did-you-know.png";
import Heading from "./Heading";
import useAOS from "../../hooks/useAOS";

function WhyDryTech(props) {
  useAOS();
  return (
    <div id={props.id} className="mt-10 flex flex-col gap-10">
      <Heading heading="Why Drytech"></Heading>
      <div className="flex flex-wrap justify-center  tab950:pr-32 tab950:pl-32">
        {whydrytech.map((row,i) => (
          <div data-aos={((i+1) % 2 !== 0) ? "fade-right" : "fade-left"} className="flex  min-w-[200px] max-w-[400px] gap-3 p-3" key={row.title}>
            <div className="flex min-w-[70px] h-[70px]">
              <img src={row.img} className="w-full" alt="whydrytech" />
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-xl font-medium">{row.title}</span>
              <span className="text-[14px] text-gray-400">{row.content}</span>
            </div>
          </div>
        ))}
      </div>
      {props.didYouKnow && (
        <div data-aos="zoom-in" className="flex flex-wrap tab650:flex-nowrap justify-center items-start tab650:text-start text-center gap-3 bg-gray-100 w-[50%] min-w-[300px] m-auto p-5">
          <div className="bg-white p-2">
            <img className="min-w-[100px]" src={didYou} alt="DidYouKnow"></img>
          </div>
          <div>
            <span className="text-[15px] text-gray-600">
              {props.didYouKnow}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default WhyDryTech;
