import React, { useEffect, useReducer } from "react";
import Enquire from "../common/Enquire";
import ButtonSection from "./components/ButtonSection";
import Banner from "../common/Banner";

//image
import banner from "../../assets/images/downloads/network-bg.png";
import ModalForm from "./components/ModalForm";

function Downloads() {
  const [localState, dispatch] = useReducer(reducer, {
    open: false,
    fileName : ""
  });

  return (
    <>
      <Banner banner={banner}></Banner>
      <ButtonSection dispatch={dispatch}></ButtonSection>
      <ModalForm state={localState} dispatch={dispatch}></ModalForm>
      <Enquire
        heading={"Subscribe to our newsletter"}
        subheading={
          "Get monthly updates about latest products and offers from Drytech."
        }
        type="subs"
        btnText="Subscribe Now"
      ></Enquire>
    </>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "setOpen":
      return { ...state, open: action.payload.open, fileName : action.payload.fileName }; // Return a new state object
    default:
      return state; // Always return the current state if the action is not recognized
  }
}

export default Downloads;
