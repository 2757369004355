import React from "react";
import Heading from "../../common/Heading";

function ButtonSection({dispatch}) {
  return (
    <div className="tab950:pr-32 tab950:pl-32 pr-8 pl-8 mt-10 flex flex-col gap-10">
      <Heading
        heading="Downloads"
        helperText={
          "We would be glad to see you joining our journey towards healthy life. To have a never-ending relationship between you and us, get this free brochure downloaded in your system."
        }
      ></Heading>
      <div className="flex justify-center flex-col gap-3 tablets:flex-row tablets:justify-evenly ">
        <div className="flex flex-col gap-3 justify-center items-center">
          <span className="text-gray-600 text-sm font-semibold">
            Drytech Main Brochure
          </span>{" "}
          {/* <a download={"Drytech Main Brochure"} href={mainBrochure}> */}
            {" "}
            <button onClick={()=>dispatch({ type : "setOpen", payload : {open : true, fileName : "Drytech Main Brochure"} })} className="p-2 pr-5 pl-5 bg-[#FF6721] font-medium text-white">
              Downloads
            </button>
          {/* </a> */}
        </div>
        <div className="flex flex-col gap-3 justify-center items-center">
          <span className="text-gray-600 text-sm font-semibold">
            Nutritional Ingredient Brochure
          </span>{" "}
          {/* <a download={"Nutritional Ingredient Brochure"} href={nutritional}> */}
            <button onClick={()=>dispatch({ type : "setOpen", payload : {open : true, fileName : "Nutritional Ingredient Brochure"} })} className="p-2 pr-5 pl-5 bg-[#FF6721] font-medium text-white">
              Downloads
            </button>
          {/* </a> */}
        </div>
      </div>
    </div>
  );
}

export default ButtonSection;
