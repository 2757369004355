import React from 'react'
import Heading from '../../common/Heading'
// image 
// import map from '../../../assets/images/network/map.png'
import map2 from '../../../assets/images/network/map2.svg'
function Globe(props) {
  return (
    <div id={props.id} className='mt-10 flex justify-center flex-col items-center gap-0'>
        <Heading heading = {"Global Reach"} helperText = "We have a very efficient Sales and Marketing team headquartered in Mumbai who reaches out to our customers all over India and internationally. Our Sales Co-ordination team always keeps our customers abreast of on the status and information pertaining to all their work orders."></Heading>
        <div>
            <img src={map2} className='w-screen' alt='network'></img>
        </div>
    </div>
  )
}

export default Globe