import React from "react";
// banner
import banner1 from "../../assets/images/services/contract-manufacturing-cta-new.png";
import banner2 from "../../assets/images/services/newsletter-bg.png";
import useAOS from "../../hooks/useAOS";
import { useNavigate } from "react-router-dom";

function Enquire(props) {
  const navigate = useNavigate();
  useAOS();
  return (
    <div style={{ backgroundImage: `url('${banner1}')` }} className="mt-10 bg-no-repeat bg-cover flex flex-col justify-center min-h-[400px] relative w-full">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

      <div className="flex flex-col tab950:flex-row gap-5 tab950:gap-0 text-center tab absolute pl-5 pr-5 com1100:pr-32 com1100:pl-32 justify-center items-center w-full z-10">
        <div className=" text-white tab950:w-1/2 w-full flex flex-col gap-1">
          {props.heading && <span data-aos="fade-right" className="text-3xl font-medium">{props.heading}</span>}
          {props.subheading && <span data-aos="fade-right" className="text-[12px]" >{props.subheading}</span>}
        </div>
        <div data-aos="fade-left" className="flex flex-wrap gap-3 tab950:flex-nowrap tab950:w-1/2 w-full justify-evenly tab950:gap-0 tab950:justify-end">
          {props.type !== "enquire" && <input className="p-2 tab650:w-1/2 w-[80%] text-sm" placeholder="Email"/>}
          <button onClick={()=>navigate('/contact')} className="text-white p-2 pr-12 text-[15px] tab650:w-1/2 max-w-[300px] w-[80%] pl-12 bg-[#FF6721]">{ props.type === "enquire" ? props.btnText || "Enquire Now" : props.btnText || "Subscribe"}</button>
        </div>
      </div>
    </div>
  );
}

export default Enquire;
