import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        // Scroll into view after a slight delay
        const scrollToElement = () => {
          element.scrollIntoView({ behavior: "smooth" });
        };

        // Delay the scrolling to ensure the element is in the DOM
        const timer = setTimeout(scrollToElement, 100); // Delay in milliseconds

        // Clear the timer if the component unmounts or the hash changes
        return () => clearTimeout(timer);
      }
    }
  }, [hash]);

  return null;
};

export default ScrollToHash;
