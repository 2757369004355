import React, { useState } from "react";
import useAOS from "../../../hooks/useAOS";
import mainBrochure from "../../../assets/pdf/Drytech-Main-Brochure-3.pdf";
import nutritional from "../../../assets/pdf/nutritional.pdf";
import { sendMail } from "../../../services/api";
import toast from "react-hot-toast";
const ModalForm = ({ state, dispatch }) => {
  const [sending, setSending] = useState(false);

  useAOS({
    duration: 500,
  });

  const [formData, setFormData] = useState(initial);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  async function handleSubmit(e) {
    try {
      setSending(true);
      e.preventDefault();
      let res = await sendMail(formData);
      if (res.status === 200) toast.success("Email sent successfully.");

      setFormData(initial);
      setSending(false);
      dispatch({ type: "setOpen", payload: false }); // Close modal after submission
      startDownload(state.fileName);
    } catch (error) {
      setSending(false);
      toast.error("Something went wrong.");
    }
  }

  const startDownload = (PdfName) => {
    // Create a link element
    const link = document.createElement("a");

    // Set the URL of the file to be downloaded (can be a public URL or a file path on the server)
    link.href =
      PdfName === "Drytech Main Brochure" ? mainBrochure : nutritional; // Replace with your file path or URL

    // Set the download attribute to the desired file name
    link.download = PdfName; // Replace with your desired file name

    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up the link element after triggering the download
    document.body.removeChild(link);
  };

  return (
    <>
      {/* Modal Structure */}
      {state.open && (
        <div
          data-aos="zoom-up"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-out"
        >
          <div
            className={`bg-white rounded-lg shadow-lg max-w-lg w-full p-6 transform transition-all duration-300 ease-out 
            ${state.open ? "opacity-100 scale-100" : "opacity-0 scale-95"}`}
          >
            {/* Close Button */}
            <button
              onClick={() => dispatch({ type: "setOpen", payload: false })}
              className="text-gray-500 hover:text-gray-700 text-2xl float-right"
            >
              &times;
            </button>

            {/* Form */}
            <h2 className="text-2xl font-semibold text-center mb-5">
              Contact Form
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Name field */}
              <div>
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>

              {/* Email field */}
              <div>
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>

              {/* Contact field */}
              <div>
                <input
                  placeholder="Contact"
                  type="tel"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>

              {/* Company field */}
              <div>
                <input
                  placeholder="Company"
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>
              {/* Country field */}
              <div>
                <input
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>
              {/* State field */}
              <div>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                />
              </div>
              {/* Reason field */}
              <div>
                <select
                  type="text"
                  id="reason"
                  placeholder="Select reason for enquire "
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                  required
                >
                  <option value={"product enquire"}>Product Enquiry</option>
                  <option value={"Other Reason"}>Other</option>
                </select>
              </div>
              {/* Product field */}
              {/* Product field */}
              {formData.reason === "product enquire" && (
                <div>
                  <textarea
                    type="text"
                    id="product_details"
                    name="product_details"
                    placeholder="Product Brief"
                    value={formData.product_details}
                    onChange={handleChange}
                    className="resize-none mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring-[#1D94D2] focus:border-[#1D94D2]"
                    required
                  />
                </div>
              )}

              {/* Submit button */}
              <div>
                <button
                  type="submit"
                  disabled={sending}
                  className={`w-full ${
                    sending ? "bg-[#186c9a]" : "bg-[#1D94D2]"
                  } text-white py-2 px-4 text-center rounded-md hover:bg-blue-600`}
                >
                  {sending ? (
                    <svg
                      className="animate-spin"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      height={25}
                      width={25}
                    >
                      <title>loading</title>
                      <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalForm;

const initial = {
  name: "",
  email: "",
  contact: "",
  company: "",
  country: "",
  reason: "product enquire",
  state: "",
  product_details: "",
  type: "brochure",
};