import React from "react";
import Banner from "../common/Banner";

// image
import banner from "../../assets/images/sustainability/sustainability-bg.png";
import WellBeingCard from "./components/WellBeingCards";
function Sustainability() {
  return (
    <>
      <Banner banner={banner} banner_title={"Sustainability"}></Banner>
      <WellBeingCard></WellBeingCard>
    </>
  );
}

export default Sustainability;
