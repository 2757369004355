import React from "react";
import { blogs } from "../data/data";
import { useNavigate } from "react-router-dom";
import useAOS from "../../../hooks/useAOS";
function BlogsList() {
  useAOS();
  let navigate = useNavigate();

  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }
  return (
    <div className="flex flex-col gap-8 mt-10 tab950:pr-32 tab950:pl-32 pr-8 pl-8">
      {blogs.map((row) => (
        <div className="flex flex-col tablets:flex-row gap-5">
          <div data-aos="fade-right" className="min-w-[300px] max-w-[500px]">
            <img alt="blogs_img" src={row.img}></img>
          </div>
          <div data-aos="fade-left" className=" flex flex-col gap-1 text-gray-400 max-w-full">
            <span className="text-[10px] ">{row.time}</span>
            <span className="text-3xl text-gray-500 font-medium">
              {row.heading}
            </span>
            <span className="text-[12px]">{row.content}</span>
            <button
              onClick={() => navigate(`/show/blog/${makeHash(row.heading)}`)}
              className="self-start border p-1 pr-4 pl-4 text-gray-500 border-[#0095DA] text-sm"
            >
              Read More
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BlogsList;
