import React from "react";
import Banner from "../common/Banner";
import { data_details } from "./data/details_data";
import { useParams } from "react-router-dom";
//components
import Nutrition from "./components/Nutrition";
import Enquire from "../common/Enquire";
import WhyDryTech from "../common/WhyDryTech";
import ProductCase from "../common/ProductCase";

function Details() {
  let { page } = useParams();
  let data = data_details[reverseFormat(page)];

  function reverseFormat(input) {
    // Replace hyphens with spaces and capitalize each word
    return input
      .replace(/-/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <>
      <Banner banner={data.banner}></Banner>
      <Nutrition page={reverseFormat(page)} data={data}></Nutrition>
      <Enquire
        heading="Collab and create a sustainable value chain in the market"
        type="enquire"
      ></Enquire>
      <WhyDryTech didYouKnow={data.didYouKnow}></WhyDryTech>
      <ProductCase heading="Other Product"></ProductCase>
    </>
  );
}

export default Details;
