import React from "react";
import useAOS from "../../hooks/useAOS";

function Heading(props) {
  useAOS(
    {
      duration : 1000
    }
  );
  return (
    <div 
      className={`flex flex-col gap-2 tablets:gap-4 justify-center items-center text-center ${props.classes}`
      }
    >
      {" "}
      {props.upperText && (
        <span data-aos="fade-down" className="tracking-widest text-[11px] uppercase text-gray-500">
          {props.upperText}
        </span>
      )}
      <span data-aos="fade-up" className="text-3xl tablets:text-4xl font-medium">{props.heading}</span>{" "}
      {props.helperText && (
        <span className={`tablets:text-sm text-[12px] tablets:w-2/3 text-gray-400  ${props?.classes?.helperText}` }>{props.helperText}</span>
      )}
      {props.p2 && (
        <span className={`w-2/3 text-gray-400  ${props?.classes?.helperText}` }>{props.p2}</span>
      )}
      {props.p3 && (
        <span className={`w-2/3 text-gray-400 ${props?.classes?.helperText}` }>{props.p3}</span>
      )}
    </div>
  );
}

export default Heading;
