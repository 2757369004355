import React from "react";
import Heading from "../../common/Heading";
import _1 from "../../../assets/images/about/timeline/beverage-whitener.png";
import { progress } from "../data/data";
// 1992 -- 2019
function Timeline() {
  return (
    <div className="mt-10 tab950:pr-32 tab950:pl-32 pr-5 pl-5">
      <Heading heading={"We’re just getting started"}></Heading>
      <div className="flex flex-col mt-5  cursor-pointer select-none overflow-x-auto p-5 ">
        <div className="flex relative top-1/2  h-[700px]">
          {progress.map((row, i) => (
            <div
              className="flex relative justify-center items-center group"
              key={row.title}
            >
              {/* card */}
              <div
                className={`bg-gray-100 group-hover:bg-[#0095DA] group-hover:text-white  z-[2] min-w-[100px] absolute  max-w-[220px] p-2 flex flex-col gap-2 left-5 ${
                  row.pos === "up"
                    ? row.size === "big"
                      ? "top-[10.5%]"
                      : "top-[20.5%]"
                    : row.size === "big"
                    ? "bottom-[8%]"
                    : "bottom-[17.5%]"
                }`}
              >
                <div className="flex gap-3 items-center">
                  <img
                    className="w-[25px] h-[20px]"
                    src={row.img}
                    alt="_imgs"
                  />
                  <span className="text-[14px] font-medium">{row.title}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-[12px]">{row.content}</span>
                </div>
              </div>
              {/* arrow  */}
              <div
                className={`flex flex-col items-center absolute left-0 ${
                  row.pos === "up"
                    ? row.size === "big"
                      ? "top-[19.5%]"
                      : "top-[34%]"
                    : row.size === "big"
                    ? "top-[50.5%]"
                    : "top-[50.5%]"
                }`}
              >
                {/* up  */}
                {row.pos === "up" && (
                  <>
                    {" "}
                    <div className="bg-gray-200 group-hover:bg-[#0095DA] rounded-full w-[13px] h-[13px]"></div>
                    <div
                      className={`bg-gray-200 w-[3px] group-hover:bg-[#0095DA] ${
                        row.size === "big" ? "h-[200px]" : "h-[100px]"
                      }`}
                    ></div>
                  </>
                )}
                {/* Mid line  */}
                <div className="group-hover:bg-[#0095DA] bg-gray-200 rounded-full w-[18px] h-[18px]"></div>
                {/* down */}
                {row.pos === "down" && (
                  <>
                    <div
                      className={`bg-gray-200 group-hover:bg-[#0095DA] w-[3px] ${
                        row.size === "big" ? "h-[200px]" : "h-[70px]"
                      }`}
                    ></div>
                    <div className="bg-gray-200 group-hover:bg-[#0095DA] rounded-full w-[13px] h-[13px]"></div>
                  </>
                )}
              </div>
              {/* year  */}
              <span className="text-l group-hover:text-[#0095DA] group-hover:border-[#0095DA] font-semibold text-gray-300 w-[200px] border-b-2  inline-block text-center ">
                {row.year}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
