import React from "react";
import Banner from "../common/Banner";
import Enquire from "../common/Enquire";
//image
import banner from "../../assets/images/blog/blog-bg.png";
import NewsList from "./components/NewsList";


function News() {
  
  return (
    <>
      <Banner banner={banner} banner_title={"News & Events"}></Banner>
      <NewsList></NewsList>
    </>
  );
}

export default News;
