// State and Menu Items
export const initial = {
    About: false,
    Products: false,
    Services: false,
    Application: false,
    Innovations: false,
    Career: false,
    Network: false,
  };
  
export const initialNested = {
    "Fruit & Vegetables": false,
    "Gum Arabic": false,
    Nutrition: false,
    Seasoning: false,
    Creamer: false,
    "Natural Color": false,
    "INSTANT FAT FILLED POWDER": false,
  };
  
 export  const pages = [
    { label: "About", to: "/about", hasDropdown: true },
    { label: "Products", to: "listing", hasDropdown: true },
    { label: "Services", to: "/services", hasDropdown: true },
    { label: "Application", to: "details", hasDropdown: true },
    { label: "Innovations", to: "/innovations", hasDropdown: true },
    { label: "Career", to: "/career", hasDropdown: true },
    { label: "Network", to: "/network", hasDropdown: true },
  ];
  
 export const dropdownItems = {
    "About": [
      { label: "What We Do", to: "/about#what-we-do" },
      { label: "Vision, Mission & Values", to: "/about#vision-mission-&-values" },
      { label: "Milestones", to: "/milestones" },
    ],
    "Network": [
      { label: "Global Reach", to: "/network#global" },
      { label: "Marketing", to: "/network#marketing" },
    ],
    "Services": [{ label: "Contract Manufacturing", to: "/services#contract-manufacturing" }],
    "Application": [
      { hasDropdown: true, label: "Snack & Savory", to: "/listing/Snack & Savory" },
      { hasDropdown: true, label: "Confectioneries", to: "/listing/Confectioneries" },
      { hasDropdown: true, label: "Beverage", to: "/listing/Beverage" },
      { hasDropdown: true, label: "Nutraceutical", to: "/listing/Nutraceutical" },
      { hasDropdown: true, label: "Convenience Food", to: "/listing/Convenience Food" },
      { hasDropdown: true, label: "Bakery", to: "/listing/Bakery" },
      { hasDropdown: true, label: "Dairy", to: "/listing/Dairy" },
      { hasDropdown: true, label: "Seasoning", to: "/listing/Seasoning" },
      { hasDropdown: true, label: "Health Food", to: "/listing/Health Food" },
      { hasDropdown: true, label: "Frozen Desserts", to: "/listing/Frozen Desserts" },
    ],
    "Innovations": [
      { label: "Research & Development", to: "/innovations#research-development" },
      { label: "Marketing Intelligence", to: "/innovations#marketing-intelligence" },
    ],
    "Career": [
      { label: "Working With DryTech", to: "/career#working-with-drytech" },
      // { label: "Career Opportunities", to: "/career#career-opportunities" },
      { label: "Why DryTech", to: "/career#why-drytech" },
    ],
    "Products": [
      { hasDropdown: true, label: "Fruit & Vegetables", to: "/listing/Fruit & Vegetables" },
      { hasDropdown: true, label: "Gum Arabic", to: "/listing/Gum Arabic" },
      { hasDropdown: true, label: "Nutrition", to: "/listing/Nutrition" },
      { hasDropdown: true, label: "Seasoning", to: "/listing/Seasoning" },
      { hasDropdown: true, label: "Creamer", to: "/listing/Creamer" },
      { hasDropdown: true, label: "Natural Color", to: "listing/Natural Color" },
      {
        hasDropdown: false,
        label: "INSTANT FAT FILLED POWDER",
        to: "/details/INSTANT FAT FILLED POWDER",
      },
    ],
  };
  
 export const nestedDropdownMenu = {
    "Fruit & Vegetables": [
      {
        label: "Tomato Powder",
        to: "/details/Tomato Powder",
      },
      {
        label: "Tamarind Powder",
        to: "/details/Tamarind Powder",
      },
      {
        label: "Fruit Powder",
        to: "/details/Fruit Powder",
      },
    ],
    "Gum Arabic": [
      {
        label: "Spray Aragum",
        to: "/details/Spray Aragum",
      },
      {
        label: "Diesol Soluble Dietary Fiber",
        to: "/details/Diesol Soluble Dietary Fiber",
      },
    ],
    Nutrition: [
      {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
      {
        label: "Caseinate",
        to: "/details/Caseinate",
      },
    ],
    Seasoning: [
      {
        label: "Tomato Powder",
        to: "/details/Tomato Powder",
      },
      {
        label: "Cheese Powder",
        to: "/details/Cheese Powder",
      },
      {
        label: "Hydrolyzed Vegetable Protein",
        to: "/details/Hydrolyzed Vegetable Protein",
      },
      {
        label: "Tamarind Powder",
        to: "/details/Tamarind Powder",
      },
      {
        label: "Lime Powder",
        to: "/details/Lime Powder",
      },
    ],
    Creamer: [
      {
        label: "Non Dairy Creamer",
        to: "/details/Non Dairy Creamer",
      },
      {
        label: "Beverage Whitener",
        to: "/details/Beverage Whitener",
      },
    ],
    "Natural Color": [
      {
        label: "Beetroot Powder",
        to: "/details/Beetroot Powder",
      },
      {
        label: "Caramel Color",
        to: "/details/Caramel Color",
      },
    ],
    "Snack & Savory": [
      {
        label: "Tomato Powder",
        to: "/details/Tomato Powder",
      },
      {
        label: "Cheese Powder",
        to: "/details/Cheese Powder",
      },
      {
        label: "Hydrolyzed Vegetable Protein",
        to: "/details/Hydrolyzed Vegetable Protein",
      },
      {
        label: "Tamarind Powder",
        to: "/details/Tamarind Powder",
      },
    ],
    Confectioneries: [
      {
        label: "Spray Aragum",
        to: "/details/Spray Aragum",
      },
      {
        label: "Natural Color",
        to: "/listing/Natural Color",
      },
    ],
    Beverage: [
      {
        label: "Spray Aragum",
        to: "/details/Spray Aragum",
      },
      {
        label: "Fruit Powder",
        to: "/details/Fruit Powder",
      },
      {
        label: "Non Dairy Creamer",
        to: "/details/Non Dairy Creamer",
      },
      {
        label: "Beverage Whitener",
        to: "/details/Beverage Whitener",
      },
      {
        label: "INSTANT FAT FILLED POWDER",
        to: "/details/INSTANT FAT FILLED POWDER",
      },
    ],
    Nutraceutical: [
      {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
      {
        label: "Non Dairy Creamer",
        to: "/details/Non Dairy Creamer",
      },
      // {
      //   label: "Amla Powder",
      //   to: "/details/Amla Powder",
      // },
    ],
    "Convenience Food": [
      {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
      {
        label: "Fruit Powder",
        to: "/details/Fruit Powder",
      },
      {
        label: "Natural Color",
        to: "/listing/Natural Color",
      },
      {
        label: "Tomato Powder",
        to: "/details/Tomato Powder",
      },
    ],
    Bakery: [
      {
        label: "Cheese Powder",
        to: "/details/Cheese Powder",
      },
      {
        label: "Spray Aragum",
        to: "/details/Spray Aragum",
      },
      {
        label: "Natural Color",
        to: "/listing/Natural Color",
      },
      {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
    ],
    Dairy: [
      {
        label: "Spray Aragum",
        to: "/details/Spray Aragum",
      },
      {
        label: "Caseinate",
        to: "/details/Caseinate",
      },
      {
        label: "Natural Color",
        to: "/listing/Natural Color",
      },
    ],
    "Health Food": [
      {
        label: "Diesol Soluble Dietary Fiber",
        to: "/details/Diesol Soluble Dietary Fiber",
      },
      {
        label: "Caseinate",
        to: "/details/Caseinate",
      }, {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
    ],
    "Frozen Desserts": [
      {
        label: "Fat Powder",
        to: "/details/Fat Powder",
      },
      {
        label: "Non Dairy Creamer",
        to: "/details/Non Dairy Creamer",
      },
    ],
  };

  export const hamPage = [
    { label: "About", to: "/about", hasDropdown: true },
    { label: "Products", to: "listing", hasDropdown: true },
    { label: "Services", to: "/services", hasDropdown: true },
    { label: "Application", to: "details", hasDropdown: true },
    { label: "Innovations", to: "/innovations", hasDropdown: true },
    { label: "Career", to: "/career", hasDropdown: true },
    { label: "Network", to: "/network", hasDropdown: true }
  ]