import React from "react";
import Banner from "../common/Banner";
import banner from "../../assets/images/about/milestones-bg.png";
import Timeline from "./components/Timeline";

function Milestones() {
  return (
    <>
      <Banner banner={banner} banner_title={"Milestones"} />
      <Timeline></Timeline>
    </>
  );
}

export default Milestones;
