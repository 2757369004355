import React, { useEffect, useState } from "react";
import slide_1 from "../../../assets/images/home/slide_1.png";
import slide_2 from "../../../assets/images/home/slider-02.jpg";
import slide_3 from "../../../assets/images/home/slider-03.jpg";
import slide_4 from "../../../assets/images/home/slider-04.jpg";
import useAOS from "../../../hooks/useAOS";

const Slider = () => {
  useAOS();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval); // Cleanup interval on component unmount
  }, [slides.length]);

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="flex transition-transform duration-700 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="w-full flex-shrink-0 relative flex flex-col justify-center"
          >
            <img
              src={slide.image}
              alt={slide.caption}
              className="w-full h-full min-h-[150px]"
            />
            <div
              data-aos="fade-right"
              className=" flex flex-col justify-center tablets:w-80 w-full tablets:rounded-2xl tablets:absolute items-start gap-3 left-[10%] p-5 tablets:p-6 bg-customColor-caption_box bg-opacity-95 text-white"
            >
              <span className="text-sm ">{slide.caption}</span>
              <span className="text-2xl tablets:text-4xl font-medium">
                {slide.heading}
              </span>
              <button className="border border-white border-1 self-left p-2 text-[14px] rounded-md font-medium uppercase">
                Check Our Products{" "}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="absolute tab650:flex hidden bottom-5 right-5 transform -translate-x-1/2 space-x-2">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-3 w-3 rounded-full cursor-pointer ${
              currentIndex === index
                ? "bg-opacity-100 bg-customColor-caption_box"
                : "bg-white bg-opacity-50"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};
const slides = [
  {
    id: 1,
    image: slide_3,
    caption: "For good food and good health",
    heading: "Fostering authenticity",
  },
  {
    id: 2,
    image: slide_2,
    caption: "Authenticity blends with the taste",
    heading: "Sizzling daily foods",
  },
  {
    id: 3,
    image: slide_4,
    caption: "Natural ingredients Enhanced Changes",
    heading: "Enriching Beverage",
  },
  {
    id: 3,
    image: slide_1,
    caption: "Freshening the moods pampering the tongues",
    heading: "Glorifying confections",
  },
];
export default Slider;
