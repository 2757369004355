import React from "react";
import Heading from "../../common/Heading";

// img
import n1 from "../../../assets/images/news/news1.jpeg";
import n2 from "../../../assets/images/home/award-from-nestle.png";
import n1_1 from "../../../assets/images/news/news06.jpeg";
import n1_2 from "../../../assets/images/news/news05.jpeg";
import n1_3 from "../../../assets/images/news/news07.jpeg";
import n1_4 from "../../../assets/images/news/news200 (1).jpeg";
import n1_5 from "../../../assets/images/news/news300.jpeg";
import n1_6 from "../../../assets/images/news/news400.jpeg";
import n1_7 from "../../../assets/images/news/news600.jpeg";
import n1_8 from "../../../assets/images/news/news700.jpeg";
import n1_9 from "../../../assets/images/news/news85.png";
import n1_10 from "../../../assets/images/news/newsT4.png";
import n1_11 from "../../../assets/images/news/newsY2.png";
import n1_12 from "../../../assets/images/news/newsY5.png";
import n1_13 from "../../../assets/images/news/newsYees.png";
import n1_14 from "../../../assets/images/news/newsYes.png";
import n1_15 from "../../../assets/images/news/news1.jpeg";
//awards
import aw1 from "../../../assets/images/common/awards-1.png";
import aw2 from "../../../assets/images/common/awards-2.png";
import aw3 from "../../../assets/images/common/awards-3.png";
import aw4 from "../../../assets/images/common/awards-4.png";
import aw5 from "../../../assets/images/common/awards-5.png";

import { useNavigate } from "react-router-dom";
import useAOS from "../../../hooks/useAOS";
import Slider from "react-slick";

function Events() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useAOS();
  let navigate = useNavigate();
  function makeHash(link) {
    link = link.replace(/-/g, "");
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }
  return (
    <div className="slider-container  tab950:mt-10 pr-8 pl-8 com1100:flex-row flex-col tab950:pr-32 tab950:pl-32 gap-2 pb-5 flex">
      <Heading
        classes={"text-start flex flex-col gap-2 pt-10"}
        heading={"News & Events"}
        upperText={"Latest"}
      ></Heading>
      <div className="flex flex-wrap tab950:flex-nowrap justify-center items-center gap-5">
        {news.map((card) => (
          <div
            data-aos={card.animation}
            onClick={() => navigate(`/show/news/${makeHash(card.title || card.content)}`)}
            className="flex flex-col justify-center items-center gap-3 cursor-pointer"
          >
            {typeof card.img === "object" ? (
              <div className="h-[220px] min-w-[350px] max-w-[400px] overflow-hidden">
                <Slider {...settings} className="mb-4">
                  {card.img.map((image, index) => (
                    <div key={index} className="h-[200px] min-w-[350px] max-w-[400px]">
                      <img
                        src={image}
                        className="max-h-[300px] w-full object-cover"
                        alt={`slide-${index}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="h-[300px] w-[400px] overflow-hidden">
                <img
                  src={card.img}
                  className="h-full w-full object-cover"
                  alt="n1"
                />
              </div>
            )}

            <span className="text-sm">{card.title || card.content}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

let news = [
  {
    animation: "fade-left",
    img: [
  
      n1_13,
      n1_11,   
      n1_9,
      n1_12,
      n1_14,
      n1_10,
    ],
    img2: n1_1,
    title:
      "Connect with Drytech - Innovation unveiled at Food Ingredient Exhibitions!",
    content: "The Speciality Ingredients has a new home now!!",
  },
  {
    animation: "fade-right",
    img: [aw1, aw2, aw3, aw4, aw5],
    content: "Appreciation from our customer speaks more than our actions",
  },
];

export default Events;
