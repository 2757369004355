import React from "react";
import Banner from "../common/Banner";
// image
import banner from "../../assets/images/contact/contact-bg.png";
// components
import Map from "./components/Map";
import Form from "./components/Form";
function Contact() {
  return (
    <>
      <Banner banner={banner} banner_title={"Contact DRYTECH"}></Banner>
      <Form></Form>
      <Map></Map>
    </>
  );
}

export default Contact;
