import React from "react";
import Heading from "../../common/Heading";
import { card } from "../data/data";
import useAOS from "../../../hooks/useAOS";

function WellBeingCards() {
  useAOS();
  let [r1, r2, r3] = [...card];
  return (
    <div className="flex mt-14 tab950:pr-32 tab950:pl-32 pr-8 pl-8 flex-col gap-10">
      <Heading
        heading="Improving well-being andadding environmental and social Value"
        helperText={
          "Our Sustainability plan is the core of our strategy for achieving business growth. Since our inception, it has provided a guide map for achieving our vision to grow business while reducing our environmental footprint and increasing our positive social impact. The responsible growth can result in success in the world of changing consumer expectations and dynamic market trends."
        }
      ></Heading>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-7 text-center font-medium text-gray-400 text-xl">
          <span>{r1.heading}</span>
          <div className="flex flex-wrap  gap-5 justify-evenly">
            {r1.cards.map((row, i) => (
              <div data-aos={row.animation} className="bg-gray-100 text-gray-400 text-sm gap-3 flex flex-col justify-center items-center p-5 max-w-[250px]" key={"r3 " + i}>
                {" "}
                <img src={row.img} alt="card_well" /> <span>{row.content}</span>{" "}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-7 text-center font-medium text-gray-400 text-xl">
          <span>{r2.heading}</span>
          <div className="flex flex-wrap  gap-5 justify-evenly">
            {r2.cards.map((row, i) => (
              <div data-aos={row.animation} className="bg-gray-100 text-gray-400 text-sm gap-3 flex flex-col justify-center items-center p-5 max-w-[250px]" key={"r3 " + i}>
                {" "}
                <img src={row.img} alt="card_well" /> <span>{row.content}</span>{" "}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-7 text-center font-medium text-gray-400 text-xl">
          <span>{r3.heading}</span>
          <div className="flex flex-wrap  gap-5 justify-evenly">
            {r3.cards.map((row, i) => (
              <div data-aos={row.animation} className="bg-gray-100 text-gray-400 text-sm gap-3 flex flex-col justify-center items-center p-5 min-w-[150px] max-w-[250px]" key={"r3 " + i}>
                {" "}
                <img src={row.img} alt="card_well" /> <span>{row.content}</span>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WellBeingCards;
