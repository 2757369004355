import React from "react";
import Banner from "../common/Banner";
// image
import banner from "../../assets/images/network/network-bg.png";
import market from "../../assets/images/network/global-marketing.png";
import Enquire from "../common/Enquire";
import Globe from "./components/Globe";
import InfoSection from "../common/InfoSection";

function Network() {
  return (
    <>
      <Banner banner={banner} banner_title={"Network"}></Banner>
      <Globe id="global"></Globe>
      <Enquire
        heading={"Collab and create a sustainable value chain in the market"}
        type="enquire"
        btnText="Partner With Us"
      ></Enquire>
      <InfoSection id="marketing" card={card} heading="Marketing"></InfoSection>
    </>
  );
}

let card = [
  {
    img: market,
    content: `“Customer is King” – as they say and will always remain our priority. Our dedicated marketing team works closely with our customers and provide them best possible recommendations. They along with our RD & A team collaborate with our customers and offer them application solutions wherever this is whenever critical..`,
  },
];

export default Network;
