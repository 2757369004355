import React from "react";
// component
import Banner from "../common/Banner";
// image
import banner_img from "../../assets/images/services/service-bg.png";
import InfoSection from "../common/InfoSection";
import card_img from "../../assets/images/services/im1.jpg";
import card_img2 from "../../assets/images/services/im2.jpg";
import card_img3 from "../../assets/images/services/im3.jpg";
// import card_img4 from "../../assets/images/services/contract-manufacturing-botteling-4.jpg";
import Enquire from "../common/Enquire";
import WhyDryTech from "../common/WhyDryTech";
import useAOS from "../../hooks/useAOS";

function Services() {
  return (
    <>
      <Banner banner={banner_img} banner_title="Services"></Banner>
      <InfoSection
        id="contract-manufacturing"
        counter={false}
        subheading_style={"text-center"}
        card={card}
        heading="Contract Manufacturing"
        subHeading="The contract manufacturer you choose for your company should be worth relying on, in terms of quality, integration and technology. We, at Drytech believe in continuous innovation and through our cutting-edge technology we are one of the leading co-partners for spray drying of the vitamin and flavor encapsulation. We are a team of industry experts and R&D professionals who vehemently strive for perfection and possess an innovative approach towards new product development.  To its adjacent we also cater to:"
      ></InfoSection>
      <Enquire
        heading="Share your requirement for CONTRACT MANUFACTURING"
        subheading=""
        type="enquire"
      ></Enquire>
      <WhyDryTech></WhyDryTech>
      <Enquire
        heading="Subscribe to our newsletter"
        subheading="Get monthly updates about latest products and offers from Drytech."
      ></Enquire>
    </>
  );
}

let card = [
  {
    img: [
      card_img2,
      card_img3,
      card_img,
    ],
    content: `At Drytech, we believe, demand for a safe and healthy food 
            ingredient must be fulfilled for a healthy lifestyle. Our quality
            ingredients lets you indulge into a world of tasty food that blends
            science with passion. We help food manufacturers create products,
            which are not only natural but also rich in nutrients. With our
            state of the art facility and well equipped RD & A center, we have
            been serving the food industry with unique and specialty ingredients
            for several years. Our range of ingredients also help our customers
            to make clean label products and elevate their sensory experience.
            We welcome you on the journey to reform and innovate your food
            products that will delight your customers..`,
  },
];
export default Services;
