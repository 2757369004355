import React from "react";
import c1 from "../../assets/images/about/counter1.png";
import c2 from "../../assets/images/about/counter2.png";
import c3 from "../../assets/images/about/counter3.png";
import c4 from "../../assets/images/about/counter4.png";
import counterImg from "../../assets/images/common/counterImg.jpeg";
import useAOS from "../../hooks/useAOS";
import Slider from "react-slick";

function InfoSection(props) {
  useAOS();
  // let {classes} = props?.classes || {};
  return (
    <div
      id={props.id}
      className=" pr-5 pl-5 tab950:pr-40 tab950:pl-40 mt-10 flex flex-col gap-3 tab950:gap-8 w-full "
    >
      {props.bigHeading && (
        <div
          data-aos="zoom-in"
          className="text-4xl text-center tablets:text-start tab950:text-5xl font-bold tab950:leading-[65px] flex  tab950:w-7/12 m-auto"
        >
          <span>
            <span className="text-[#0095DA]">&ldquo;</span>
            {props.bigHeading}
            <span className="text-[#0095DA]"> &bdquo;</span>
          </span>
        </div>
      )}
      {props.heading && (
        <div className="text-2xl tab950:text-3xl font-semibold flex gap-2  m-auto">
          <span>{props.heading}</span>
        </div>
      )}
      {props.subHeading && (
        <>
          {props.p1 && (
            <span
              data-aos="fade-up"
              className={`text-sm text-gray-500 items-baseline ${
                props.subheading_style ? props.subheading_style : ""
              }`}
            >
              {props.p1}
            </span>
          )}
          <span
            data-aos="fade-up"
            className={`text-sm text-gray-500 items-baseline ${
              props.subheading_style ? props.subheading_style : ""
            }`}
          >
            {props.subHeading}
          </span>
          {props.p3 && (
            <span
              data-aos="fade-up"
              className={`text-sm text-gray-500 items-baseline ${
                props.subheading_style ? props.subheading_style : ""
              }`}
            >
              {props.p3}
            </span>
          )}
          {props.mail && (
            <span
              data-aos="fade-up"
              className={`text-sm text-gray-500 items-baseline ${
                props.subheading_style ? props.subheading_style : ""
              }`}
            >
              Join our innovative team at DryTech India, where we drive cutting-edge solutions and offer dynamic career growth opportunities. Apply now at <a href="mailto:careers@drytechindia.com" className="text-blue-500">careers@drytechindia.com</a> 
            </span>
          )}
        </>
      )}
      {props.card &&
        props.card.map((row, i) => (
          <div
            className="grid grid-cols-1 tablets:grid-cols-2 gap-5 pb-3"
            key={i}
          >
            {typeof row.img === "object" ? (
              <Slider {...settings} className="mb-4">
                {row.img.length &&
                  row.img.map((image) => (
                    <div data-aos="zoom-in" className="card_img">
                      <img
                        className="w-[90%] h-[300px] m-auto"
                        src={image}
                        alt="card_images"
                      ></img>
                    </div>
                  ))}
              </Slider>
            ) : (
              <div data-aos="zoom-in" className="card_img ">
                <img
                  className="w-[90%] m-auto"
                  src={row.img}
                  alt="card_images"
                ></img>
              </div>
            )}
            <div
              data-aos="fade-left"
              className="content border-l text-gray-500 border-[#0095DA] pl-5"
            >
              <span className="text-sm">{row.content}</span>
            </div>
          </div>
        ))}
              {props.counterImg && (
        <div>
          <img src={counterImg} className="w-full" alt="counter_img"></img>
        </div>
      )}
      {props.counter && (
        <div
          data-aos="fade-up"
          className="flex flex-wrap justify-center  gap-4"
        >
          {counter.map((row) => (
            <div
              className="flex gap-3 flex-wrap flex-col min-w-[150px] max-w-[230px] relative"
              key={row.title}
            >
              <img src={row.img} alt="counter_img" />
              <div className="absolute flex flex-col items-center w-full h-full justify-center p-3">
                <span className="text-3xl text-white font-bold">
                  {row.number + "" + row.suffix}
                </span>
                <span className="text-sm text-center text-white">{row.title}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

let counter = [
  {
    img: c4,
    title: "Years Of Experience",
    number: 30,
    suffix: " +",
  },
  {
    img: c2,
    title: "Production Capacity Per Annum",
    number: 100000,
    suffix: " MT",
  },
  {
    img: c3,
    title: "Multi-Stage Spray Dryers",
    number: 12,
    suffix: "",
  },
  {
    img:  c1,
    title: "Of Land",
    number: 35,
    suffix: " ACRES",
  },
  {
    img: c3,
    title: "Micro Encapsulation Technology",
    number: "",
    suffix: "",
  },
  {
    img: c4,
    title: "Co-partner and Co-packer of infant and clinical nutrition products.",
    number: "",
    suffix: "",
  },
];
const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export default InfoSection;
