import w1 from '../../assets/images/services/largest-manufacturer.png'
import w2 from '../../assets/images/services/quality-product.png'
import w3 from '../../assets/images/services/human-interventions.png'
import w4 from '../../assets/images/services/domain-expertise.png'
import w5 from '../../assets/images/services/customer-friendly.png'
import w6 from '../../assets/images/services/after-sales-service.png'

export const whydrytech = [
    {
        img : w1,
        title : "Asia’s largest manufacturer",
        content : "Drytech is the largest manufacturer in Asia for Spray Aragum."
    },
    {
        img : w2,
        title : "Domain Expertise",
        content : "Drytech is a team of industry experts and R & D professionals who work passionately towards product innovation. ‘We simplify nature and add ease to your innovation’ – thereby we strive to retain and offer goodness of taste and nutrition present in nature to our customers through our spray dried food ingredients."
    },
    {
        img : w3,
        title : "A Zero human interventions",
        content : "The world is progressing and so are we, in terms of technological advancements. At Drytech, we encourage Zero-Human Interventions during all the stages of manufacturing and closely follow Good Manufacturing Practices. This enable us to offer you our quality products consistently."
    },
    {
        img : w4,
        title : "Domain Expertise",
        content : "DOur position as a leader- ‘Spray Dried Specialty Food Ingredient Manufacturer’ is a result of our domain expertise in the business that we operate. This is possible due to our everlasting commitment to product innovation and offer quality products that consistently meet our customer’s expectations."
    },
    {
        img : w5,
        title : "Customer Friendly Approach",
        content : "Customer is at the center of all the activities we perform. We collaborate with customers to better understand their ever growing needs - which inspire us to innovate. We are always committed in meeting customer’s expectations and providing them friendly and cordial customer services."
    },
    {
        img : w6,
        title : "Customer Friendly Approach",
        content : "Our promise to offer quality products to our customers on a consistent basis is well supported with our after sales service. We value our customers and are always ready and willing to assist them with our friendly and cordial customer service and fulfill their expectations."
    },
]

export const tags = [
    {
        title : "Beetroot Powder",
        content : "",
    },
    {
        title : "Beetroot Powder Manufacturer",
        content : ""
    },
    {
        title : "Beverage Whitener For Cake",
        content : ""
    },
    {
        title : "Caramel Color",
        content : ""
    },
    {
        title : "Caramel Powder",
        content : ""
    },
    {
        title : "Casein Nutrition",
        content : ""
    },
    {
        title : "General",
        content : ""
    },
    {
        title : "Media",
        content : ""
    },
]