import React from "react";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

// data
import { dropdownItems, pages } from "./data";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";

const HamNav = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }

  function handleNav(link) {
    link = makeHash(link)
    navigate(link);
    setIsOpen(false);
  }

  return (
    <div className="absolute flex flex-col right-0 top-0 border-none pt-5 bg-white">
      <button className="self-end p-5" onClick={toggleDrawer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <Drawer
        style={{height : '100%'}}
        size={200}
        open={isOpen}
        onClose={toggleDrawer}
        className=" flex flex-col"
        direction="right"
      >
        <button className="self-end p-5" onClick={toggleDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
        <Sidebar
          width="200px"
          collapsedWidth="0"
          backgroundColor="transparent"
          toggled={isOpen}
        >
          <Menu>
            {pages.map((row) => (
              <SubMenu label={row.label}>
                {dropdownItems[row.label] &&
                  dropdownItems[row.label].map(
                    (subLink =><MenuItem onClick={() => handleNav(subLink.to)}>
                        {subLink.label}
                      </MenuItem> )
                  )}
              </SubMenu>
            ))}
          </Menu>
        </Sidebar>
      </Drawer>
    </div>
  );
};

export default HamNav;
