import React from "react";
import Heading from "../../common/Heading";
import { fruits } from "../data/data";
function Nutrition({ data, page }) {
  return (
    <>
      {data && (
        <div className="mt-10 pr-8 pl-8 tab950:pr-32 tab950:pl-32 flex flex-col gap-8 ">
          <Heading heading={page}></Heading>
          <div className="flex flex-col-reverse tab950:flex-row justify-center items-center tab950:items-start gap-5 tab950:justify-between">
            <div data-aos="fade-right" className="flex flex-col text-gray-400 text-sm gap-4 w-[95%] tab950::w-[65%]">
              {data.description.map((row) => (
                <span>{row}</span>
              ))}
              {data.desList && <ul className="list-disc pl-4">
                 {data.desList.map(row=><li>{row}</li>)}
                </ul>}
              <ProductTable table={data.table}></ProductTable>
            </div>
            <div data-aos="fade-left" className="flex justify-end ">
              <img
                src={data.product}
                alt="fruit"
                className="w-full max-h-[300px]"
              ></img>
            </div>
          </div>
          {data.fruits && <Fruits />}
        </div>
      )}
    </>
  );
}

function ProductTable({ table }) {
  return (
    <div className="overflow-x-auto w-full">
      <table className="min-w-[700px] border-collapse">
        <thead>
          <tr className="bg-[#0095DA] font-medium text-white text-left">
            <th className="p-2 border border-gray-200 font-medium">Product</th>
            <th className="p-2 border border-gray-200 font-medium">
              Industry Segment
            </th>
            <th className="p-2 border border-gray-200 font-medium">
              Application
            </th>
          </tr>
        </thead>
        <tbody>
          {table.map((row) => (
            <tr className="bg-gray-100 text-gray-500 text-[13px]">
              <td className="p-2  border border-gray-200">{row?.product}</td>
              <td className="p-2  text-center border border-gray-200">
                {row?.industry}
              </td>
              <td className="p-2  border border-gray-200">
                {row?.application}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Fruits() {
  return (
    <div className="flex mt-8 flex-wrap justify-evenly items-baseline gap-10">
      {fruits.map((row) => (
        <div className="flex mt-10 flex-col items-center relative border p-5 text-center min-w-[100px]  max-w-[300px] gap-2">
          <div className="absolute bg-white p-4 rounded-full border -top-[20%]">
            <img src={row.img} alt={row.name}></img>
          </div>
          <span className="text-lg text-[#0095DA] mt-5">{row.name}</span>
          <span className="text-[12px] text-gray-400">{row.content}</span>
        </div>
      ))}
    </div>
  );
}

export default Nutrition;
