import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// image
import mission_img from "../../../assets/images/about/our-journey.png";
import value from "../../../assets/images/about/value.png";
import vision from "../../../assets/images/about/vision.png";
import mission from "../../../assets/images/about/mission.png";

function Mission() {
  let slickRef = useRef(null);

  function next() {
    slickRef.slickNext();
  }
  function prev() {
    slickRef.slickPrev();
  }

  return (
    <div id= "vision-mission-&-values" className="flex relative pr-5 pl-5 flex-wrap tab950:flex-nowrap com1100:pr-40 com1100:pl-40 mt-10 gap-5 justify-center">
      <div className="w-[400px]">
        <img src={mission_img} alt="mission_img"></img>
      </div>
      <div className="tab950:w-1/2 text-center tab950:text-start items-center w-full flex flex-col gap-3 tab950:items-start">
        <span className=" text-sm pt-2 text-gray-500">
          In 1992, we laid the foundation that even today rests on dedication
          and trust. Over the years, we have built an everlasting relationship
          with customers through our passion for innovation and technological
          excellence and emerged as India’s leading specialty ingredient
          provider for the food and beverage industry.
        </span>
        <button className="text-[13px] border border-[#0095DA] p-3 pr-4 pl-4 text-gray-500">
          Follow Our Journey
        </button>
      </div>
      <div className="tab950:absolute  bottom-[8%] right-[20%] tab950:w-[50%] min-w-[200px] max-w-[500px]">
        <Slider ref={(slider) => (slickRef = slider)} {...settings}>
          {mission_card.map((row, i) => (
            <div className=" bg-[#EFF0F2] p-5" key={i}>
              <div className="flex gap-4 items-start">
                <div className="w-[200px]">
                  <img src={row.img} alt={row.title} className="mb-4 w-full" />
                </div>
                <div>
                  <span className=" text-2xl font-bold">{row.title}</span>
                  <p className="text-gray-500 mt-2">{row.content}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="hidden tab950:flex absolute bottom-[5%] left-[-10%]">
          <button
            onClick={prev}
            className="bg-[#47B6F0] hover:bg-[#1D94D2] p-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="size-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
          <button
            onClick={next}
            className="bg-[#47B6F0] hover:bg-[#1D94D2] p-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="size-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

const settings = {
  dots: false,
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

let mission_card = [
  {
    img: mission,
    content:
      "Our mission is to build a world-class organization that is supported by knowledge, technology, and an understanding of the social and economic framework of the processed food industry at global and local levels.",
    title: "Mission",
  },
  {
    content:
      "We aim to build a world-class organization that is supported by knowledge, technology, and an understanding of the social and economic framework of the processed food industry.",
    title: "Value",
    img: value,
  },
  {
    content:
      "We are a team of adventurous enthusiasts driven by perseverance. We encourage innovations in the process and offer a friendly and cordial customer service to our esteemed business partners.",
    title: "Vision",
    img: vision,
  },
];

export default Mission;
