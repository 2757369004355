import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Milestones from "./pages/about/Milestones";
import Services from "./pages/services/Services";
import Innovations from "./pages/innovations/Innovations";
import Network from "./pages/network/Network";
import Career from "./pages/career/Career";
import OurClient from "./pages/our_client/OurClient";
import Contact from "./pages/contact/Contact";
import Sustainability from "./pages/sustainablity/Sustainablity";
import Downloads from "./pages/downloads/Downloads";
import Blog from "./pages/blog/Blog";
import News from "./pages/news_events/NewsEvents";
import Listing from "./pages/products/Listing";
import Details from "./pages/products/Details";
import Show from "./pages/common/Show";
import Loader from "./layout/utils/Loader";

function App() {
  const location = useLocation(); // Track the current location
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set loader when route changes
    setLoading(true);
    // Simulate a short delay, you can adjust this according to real data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/milestones" element={<Milestones />} />
            <Route path="/services" element={<Services />} />
            <Route path="/our-client" element={<OurClient />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/innovations" element={<Innovations />} />
            <Route path="/network" element={<Network />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/download" element={<Downloads />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/news" element={<News />} />
            <Route path="/show/:page/:title" element={<Show />} />
            <Route path="/listing/:page" element={<Listing />} />
            <Route path="/details/:page" element={<Details />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default function RootApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
