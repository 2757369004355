import React, { useState } from "react";
import Heading from "../../common/Heading";
import _1 from "../../../assets/images/home/gums-and-hydrocolloids.jpg";
import _2 from "../../../assets/images/home/caseinates.jpg";
import _3 from "../../../assets/images/home/nutritional-ingredients.jpg";
import _4 from "../../../assets/images/home/seasoning-ingredients.jpg";
import _5 from "../../../assets/images/home/creamers.jpg";
import _6 from "../../../assets/images/home/caseinates.jpg";
import _7 from "../../../assets/images/home/natural-colorant.jpg";
import _8 from "../../../assets/images/home/dietary-fibers.jpg";
import { useNavigate } from "react-router-dom";
import useAOS from "../../../hooks/useAOS";
function View() {
  const navigate = useNavigate();
  useAOS();

  return (
    <dvi  className="flex flex-col mt-10">
      <Heading heading={"View our offerings"} upperText={"Product"}></Heading>
      <div data-aos="zoom-in" className=" tablets:grid mt-3 hidden grid-cols-1 tab950:grid-cols-2 p-5 pr-10 pl-10 tab950:pr-28 tab950:pl-28 text-center h-[700px]">
        <div className="grid grid-cols-1 tab950:grid-cols-2 relative">
          <div
            style={{ backgroundImage: `url('${_1}')` }}
            className={`flex flex-col group items-center justify-center w-1/2 h-[35%] tablets:absolute transition-all ease-in-out duration-500  hover:h-[70%] hover:z-10 hover:w-full z-[1]`}
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Gums & Hydrocollids
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Gums & Hydrocollids"].heading}
              </span>
              <span className="text-sm">
                {data["Gums & Hydrocollids"].content}
              </span>
              <button
                onClick={() => navigate("/listing/gum-arabic")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_2}')` }}
            className="flex group flex-col items-center justify-center absolute transition-all ease-in-out duration-500  hover:w-full z-[1] hover:z-10 h-[70%] right-0 w-1/2 row-span-2 "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Nutritional Ingredients
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Nutritional Ingredients"].heading}
              </span>
              <span className="text-sm">
                {data["Nutritional Ingredients"].content}
              </span>
              <button
                onClick={() => navigate("/listing/nutrition")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_3}')` }}
            className="flex flex-col group items-center justify-center h-[35%] absolute transition-all ease-in-out duration-500  hover:h-[70%] hover:w-full z-[1] hover:z-10 w-1/2  bottom-[30%] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Fruits & Vegetable Powder
            </span>
            {console.log(data["Fruits & Vegetable Powder"])}

            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Fruits & Vegetable Powder"].heading}
              </span>
              <span className="text-sm">
                {data["Fruits & Vegetable Powder"].content}
              </span>
              <button
                onClick={() => navigate("/listing/fruit-&-vegetables")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black  text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_4}')` }}
            className="flex group flex-col items-center justify-center absolute transition-all ease-in-out duration-500  hover:h-[65%] hover:w-full z-[1] hover:z-10 bottom-0 h-[30%] w-full col-span-2  "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Seasoning Ingredients
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Seasoning Ingredients"].heading}
              </span>
              <span className="text-sm">
                {data["Seasoning Ingredients"].content}
              </span>
              <button
                onClick={() => navigate("/listing/seasoning")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 tab950:grid-cols-2 relative">
          <div
            style={{ backgroundImage: `url('${_5}')` }}
            className="flex group flex-col items-center justify-center w-full h-[35%] absolute transition-all ease-in-out duration-500  hover:h-[70%] hover:z-10 col-span-2 z-[1]"
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Creamers
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">{data["Creamers"].heading}</span>
              <span className="text-sm">{data["Creamers"].content}</span>
              <button
                onClick={() => navigate("/listing/creamer")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_6}')` }}
            className="flex group flex-col items-center justify-center absolute transition-all ease-in-out duration-500  hover:w-full z-[1] hover:z-10 h-[35%] hover:h-[70%] right-0 w-1/2 bottom-[30%]"
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Caseinates
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">{data["Caseinates"].heading}</span>
              <span className="text-sm">{data["Caseinates"].content}</span>
              <button
                onClick={() => navigate("/details/Caseinate")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_7}')` }}
            className="flex group flex-col items-center justify-center h-[35%] absolute transition-all ease-in-out duration-500  hover:h-[70%] hover:w-full z-[1] hover:z-10 w-1/2  bottom-[30%] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Natural Colorant
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Natural Colorant"].heading}
              </span>
              <span className="text-sm">
                {data["Natural Colorant"].content}
              </span>
              <button
                onClick={() => navigate("/listing/natural-color")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_8}')` }}
            className="flex group flex-col items-center justify-center absolute transition-all ease-in-out duration-500  hover:h-[65%] hover:w-full z-[1] hover:z-10 bottom-0 h-[30%] w-full col-span-2  "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Dietary Fibres
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Dietary Fibers"].heading}
              </span>
              <span className="text-sm">{data["Dietary Fibers"].content}</span>
              <button
                onClick={() =>
                  navigate("/details/Diesol Soluble Dietary Fiber")
                }
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="tablets:hidden mt-3 grid grid-cols-1 p-5 pr-10 pl-10 tab950:pr-28 tab950:pl-28 text-center h-auto">
        <div className="flex flex-col">
          <div
            style={{ backgroundImage: `url('${_1}')` }}
            className={`flex flex-col group items-center justify-center h-[100px] w-full transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1]`}
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Gums & Hydrocollids
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Gums & Hydrocollids"].heading}
              </span>
              <span className="text-sm">
                {data["Gums & Hydrocollids"].content}
              </span>
              <button
                onClick={() => navigate("/listing/gum-arabic")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_2}')` }}
            className="flex group flex-col items-center justify-center h-[100px]  w-full  transition-all ease-in-out duration-500  hover:h-auto hover:z-10  z-[1] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Nutritional Ingredients
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Nutritional Ingredients"].heading}
              </span>
              <span className="text-sm">
                {data["Nutritional Ingredients"].content}
              </span>
              <button
                onClick={() => navigate("/listing/nutrition")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_3}')` }}
            className="flex flex-col group items-center  h-[100px] justify-center w-full  transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Fruits & Vegetable Powder
            </span>
            {console.log(data["Fruits & Vegetable Powder"])}

            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Fruits & Vegetable Powder"].heading}
              </span>
              <span className="text-sm">
                {data["Fruits & Vegetable Powder"].content}
              </span>
              <button
                onClick={() => navigate("/listing/fruit-&-vegetables")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_4}')` }}
            className="flex group flex-col items-center justify-center w-full transition-all ease-in-out duration-500 h-[100px] hover:h-[100%] z-[1] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Seasoning Ingredients
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Seasoning Ingredients"].heading}
              </span>
              <span className="text-sm">
                {data["Seasoning Ingredients"].content}
              </span>
              <button
                onClick={() => navigate("/listing/seasoning")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div
            style={{ backgroundImage: `url('${_5}')` }}
            className="flex flex-col group items-center justify-center h-[100px] w-full transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1]"
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Creamers
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">{data["Creamers"].heading}</span>
              <span className="text-sm">{data["Creamers"].content}</span>
              <button
                onClick={() => navigate("/listing/creamer")}
                className="border rounded-md hover:bg-white border-white transition-all duration-500text-white text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_6}')` }}
            className="flex flex-col group items-center justify-center h-[100px] w-full transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1]"
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Caseinates
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">{data["Caseinates"].heading}</span>
              <span className="text-sm">{data["Caseinates"].content}</span>
              <button
                onClick={() => navigate("/details/Caseinate")}
                className="border rounded-md hover:bg-white text-white hover:text-black border-white transition-all duration-500  text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_7}')` }}
            className="flex flex-col group items-center justify-center h-[100px] w-full transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1] "
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Natural Colorant
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Natural Colorant"].heading}
              </span>
              <span className="text-sm">
                {data["Natural Colorant"].content}
              </span>
              <button
                onClick={() => navigate("/listing/natural-color")}
                className="border rounded-md hover:bg-white text-white hover:text-black border-white transition-all duration-500  text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url('${_8}')` }}
            className="flex flex-col group items-center justify-center h-[100px] w-full transition-all ease-in-out duration-500  hover:h-[100%] hover:z-10  z-[1]"
          >
            <span className="group-hover:hidden text-white text-xl font-medium">
              Dietary Fibres
            </span>
            <div className="group-hover:flex hidden flex-col text-white text-start p-5 justify-start gap-3">
              <span className="font-medium">
                {data["Dietary Fibers"].heading}
              </span>
              <span className="text-sm">{data["Dietary Fibers"].content}</span>
              <button
                onClick={() =>
                  navigate("/details/Diesol Soluble Dietary Fiber")
                }
                className="border rounded-md hover:bg-white border-white transition-all duration-500 text-white hover:text-black text-[14px] self-start p-2"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </dvi>
  );
}

let data = {
  "Gums & Hydrocollids": {
    heading: "Gums & Hydrocollids",
    content:
      "In our range of sustainable food products, we also offer Gums and Hydrocolloids for the industry segments like Confectionery, Beverages, Bakery, Flavor, Pharmaceutical, Snacks & Savory, Dairy and Nutraceutical. Drytech being the largest manufacturer of Spray Dried Gum Arabic in Asia, we ensure the quality sourcing of Gum Acacia. We convert these light golden crystals into fine Spray Dried Powder to convenient use as Food Ingredient. The entire conversion is done with zero human intervention to maintain the highest standard quality.",
  },
  "Nutritional Ingredients": {
    heading: "Nutritional Ingredients",
    content:
      "Nutritional Ingredients plays an important role in improving the quality and consistency of the finished food products. Also being useful in powder form as a dietary supplement, Drytech offers the range of nutritional ingredients to help improve nutritional features and enrich your food products with amino acids, creaminess, mouth feel and the appearance without losing its natural essence. Our Nutritional Ingredients can be used in the industry segments like convenience food, beverages, baby food, dairy, bakery, meat production, food supplements, health products and seasoning.",
  },
  "Fruits & Vegetable Powder": {
    heading: "Fruit & Vegetable Powder",
    content:
      "Drytech Offers Fruit and vegetable powders that are rich in vitamins, carotenoids, ascorbic acid, minerals and dietary fibre. We convert Fruits and vegetables into powder form so that they become easy to preserve, transport, store and use as additional natural ingredients in your food products.",
  },
  "Seasoning Ingredients": {
    heading: "Seasoning Ingredients",
    content:
      "Drytech offers a range of seasoning ingredients that intensifies your food products without affecting its nutritional quality. We produce the most promising seasoning ingredients made out of natural materials that can be used in the industry segments that deals with convenience food, instant premixes, oriental cuisines, bakery and meaty flavor and culinary seasoning.",
  },
  Creamers: {
    heading: "Creamers",
    content:
      "From a finest spray dried Non-Dairy Creamer to a free-flowing Beverage whitener, Drytech offers a wide range of creamers for your food products. We specially develop our Non Dairy Creamers and Beverage whitener on basis of applications such as coffee, tea, smoothies, shakes, nutritional beverages, Infant food formulas, bakery food, fat-filled milk powders, seasoning, convenience foods, frozen desserts, and many",
  },
  "Natural Colorant": {
    heading: "Natural Colorant",
    content:
      "Drytech produces and supplies Natural Colours to enhance the feel and appearance of your food products. We select the best quality raw materials to get the highest quality natural colour. Our authentic approach enables us to deliver you the best natural colours in the form of powders that generates beautiful hues of appearance in your food products.",
  },
  Caseinates: {
    heading: "Caseinates",
    content:
      "Casein is an amorphous white solid with a pleasing odor that can be used in a powder form as a dietary supplement. Drytech produces 100% soluble caseinates that can be used as an effective emulsifier and stabilizer with good foaming capabilities. Our caseinates can be used in the industry segments dealing with dairy, bakery, meat production, and beverage and food supplements.",
  },
  "Dietary Fibers": {
    heading: "Dietary Fibers",
    content:
      "With offering enhancing and seasoning ingredients, Drytech also offers soluble and insoluble Dietary Fibers for your food products. We believe in delivering you the quality and as far as human health is concerned, dietary fibres are an essential source of nutrition to our body.",
  },
};

export default View;
