import s1 from "../../../assets/images/sustainability/Group-1.png";
import s2 from "../../../assets/images/sustainability/Group-2.png";
import s3 from "../../../assets/images/sustainability/Group-3.png";
import s4 from "../../../assets/images/sustainability/Group-4.png";
import s5 from "../../../assets/images/sustainability/Group-5.png";
import s6 from "../../../assets/images/sustainability/Group.png";

export const card = [
  {
    heading: "Improving the Well-Being",
    cards: [
      {
        animation : "fade-right",
        img: s1,
        content:
          "Drytech supports a village to provide all basic amenities to its citizens.",
      },
      {
        animation : "fade-down",
        img: s2,
        content: "Provide the academic books and school uniforms to children.",
      },
      {
        animation : "fade-left",
        img: s3,
        content: "Installation of water coolers in public places and schools.",
      },
    ],
  },
  {
    heading: "Reducing Environmental impact",
    cards: [
      {
        animation : "fade-right",
        img: s4,
        content: "Tree plantation programs.",
      },
      {
        animation : "fade-left",
        img: s5,
        content: "No Plastic Usage.",
      },
    ],
  },
  {
    heading: "Improving livelihood",
    cards: [
      {
        animation : "fade-down",
        img: s6,
        content: "Equal Opportunity Employer.",
      },
    ],
  },
];
