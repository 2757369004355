import React from "react";
import WhyDryTech from "../common/WhyDryTech";
import Enquire from "../common/Enquire";
import Banner from "../common/Banner";

// image
import banner from "../../assets/images/innovations/innovation-bg.png";
import InfoSection from "../common/InfoSection";
import card_img from "../../assets/images/innovations/Untitled-design.jpg";
import card_img2 from "../../assets/images/innovations/marketing-intelligence.png";
import MarketIng from "./components/MarketIng";

function Innovations() {
  return (
    <>
      <Banner banner={banner} banner_title="Innovations"></Banner>
      <InfoSection
      id='research-development'
        card={card}
        bigHeading="We simplify nature to add ease to your innovation"
        subHeading="The food habits of the people around the globe have been changing and their preferences are mainly influenced by taste, texture and nourishment provided by food products. It has indeed become an imperative aspect for the processed food manufacturers to understand consumer insights and preferences and develop food products that meets their requirements."
      ></InfoSection>
      <MarketIng id="marketing-intelligence"></MarketIng>
      <Enquire
        heading="Collab and create a sustainable value chain in the market"
        type="enquire"
        btnText="Partner With Us"
      ></Enquire>
      <WhyDryTech></WhyDryTech>
    </>
  );
}

let card = [
  {
    img: card_img,
    content: `At Drytech, we believe, demand for a safe and healthy food 
              ingredient must be fulfilled for a healthy lifestyle. Our quality
              ingredients lets you indulge into a world of tasty food that blends
              science with passion. We help food manufacturers create products,
              which are not only natural but also rich in nutrients. With our
              state of the art facility and well equipped RD & A center, we have
              been serving the food industry with unique and specialty ingredients
              for several years. Our range of ingredients also help our customers
              to make clean label products and elevate their sensory experience.
              We welcome you on the journey to reform and innovate your food
              products that will delight your customers..`,
  },
];

export default Innovations;
