import React, { useRef } from "react";
import Heading from "./Heading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import card_1 from "../../assets/images/common/partner_1.png";
import card_2 from "../../assets/images/common/partner_2.png";
import card_3 from "../../assets/images/common/partner_3.png";
import card_4 from "../../assets/images/common/partner_4.png";
import card_5 from "../../assets/images/common/partner_5.jpg";
import card_6 from "../../assets/images/common/partner_6.jpg";
import card_7 from "../../assets/images/common/partner_7.jpg";
import card_8 from "../../assets/images/common/partner_8.jpg";
import card_9 from "../../assets/images/common/partner_9.jpg";
import card_10 from "../../assets/images/common/partner_10.jpg";
import card_11 from "../../assets/images/common/partner_4.jpg";
import useAOS from "../../hooks/useAOS";
import { useNavigate } from "react-router-dom";

function ProductCase(props) {
  return (
    <div className="case-container flex flex-col gap-4 justify-evenly pr-4 pl-4 tab950:pr-28 tab950:pl-28 mt-10">
      <div className="case-content flex flex-col justify-center items-center text-center gap-2">
        <Heading
          helperText={props.helperText}
          heading={props.heading}
          upperText={props.upperText}
        ></Heading>
      </div>
      <ShowCase />
    </div>
  );
}

function ShowCase() {
  useAOS();
  let slickRef = useRef(null);
  const navigate = useNavigate();

  function next() {
    slickRef.slickNext();
  }
  function prev() {
    slickRef.slickPrev();
  }
  return (
    <div data-aos="fade-up" className="slider-container relative">
      <Slider ref={(slider) => (slickRef = slider)} {...settings}>
        {cards.map((card) => (
          <div className="relative p-3" onClick={()=>navigate(card.to)}>
            <div>
              <img src={card.img} className="w-full" alt="partners"></img>
            </div>
            <div className="absolute p-3 bg-white opacity-75 w-full bottom-5">
              <span className="text-xl font-normal">{card.title}</span>
            </div>
          </div>
        ))}
      </Slider>
      {/* // control button  */}
      <div className="flex absolute w-full justify-between bottom-[-8%] ">
        <button onClick={prev} className=" p-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <button onClick={next} className=" p-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

let cards = [
  {
    img: card_1,
    title: "CONFECTIONERIES",
    to: "/listing/Confectioneries"
  },
  {
    img: card_2,
    title: "CONVENIENCE FOOD",
    to: "/listing/Convenience Food"
  },
  {
    img: card_3,
    title: "DAIRY",
    to : "/listing/Dairy"
  },
  {
    img: card_4,
    title: "FROZEN DESSERTS",
    to : "/listing/Frozen Desserts"

  },
  {
    img: card_5,
    title: "BEVERAGE",
    to : "/listing/Beverage"
  },
  {
    img: card_6,
    title: "HEALTH FOOD",
    to : "/listing/Health Food"

  },
  {
    img: card_7,
    title: "NATURAL COLOR",
    to : "/listing/Natural Color"
  },
  {
    img: card_8,
    title: "NUTRACEUTICAL",
    to : "/listing/Nutraceutical"
  },
  {
    img: card_9,
    title: "SEASONING",
    to : "/listing/Seasoning"
  },
  {
    img: card_10,
    title: "SNAKES & SAVOURY",
    to : "/listing/Snack & Savory"
  },
  {
    img: card_11,
    title: "BAKERY",
    to : "/listing/Bakery"
  },
];

var settings = {
  arrows: true,
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default ProductCase;
