import React from "react";
import { tags } from "../data/data";

function Left(params) {
  return (
    <div className="tablets:flex hidden flex-col p-5 gap-10 w-full ">
      {/* Newsletters */}
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <span className="text-[15px] font-medium">Newsletters</span>
          <span className="text-[12px]">
            Get monthly updates about latest products and offers from Drytech.
          </span>
        </div>
        <div className="flex flex-wrap gap-2 com1100:gap-0 ">
          <input
            className="p-1 text-sm border flex-1"
            type="email"
            placeholder="Email ID"
          ></input>
          <button className="text-white max-w-full bg-[#0095DA] p-1 text-[12px]">
            SUBSCRIBE
          </button>
        </div>
      </div>
      {/* Tags  */}
      <div className="flex flex-col gap-3">
        <span className="text-[15px] font-medium">Tags Clouds</span>
        <div className="flex gap-2 flex-wrap">
          {tags.map((row) => (
            <button
              key={row.title}
              className="p-1 transition duration-300 hover:bg-[#0095DA] hover:text-white text-gray-500 hover:border-white text-[11px] border bg-white"
            >
              {row.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Left;
