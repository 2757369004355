import React from "react";
import card_img2 from "../../../assets/images/innovations/marketing-intelligence.png";
import Heading from "../../common/Heading";
import useAOS from "../../../hooks/useAOS";

function MarketIng(props) {
  useAOS();
  return (
    <div
      id={props.id}
      className="flex flex-col bg-gray-100 pr-10 pl-10 tab950:pr-36 tab950:pl-36  p-10 gap-10 mt-10"
    >
      <Heading heading="Marketing Intelligence"></Heading>
      <div className="grid grid-cols-1 tablets:grid-cols-2 gap-5 pb-3" key={"MarketIng"}>
        <div data-aos="fade-right" className="card_img m-auto tablets:m-0">
          <img src={card.img} alt="card_images"></img>
        </div>
        <div data-aos="fade-left" className="content border-l text-gray-500 border-[#0095DA] pl-5">
          <span>{card.content}</span>
        </div>
      </div>
    </div>
  );
}

const card = {
  img: card_img2,
  content: `The food industry accounts for USD 12.24 trillion market. With globalization, technology advancement and increased competition, there is always a scope for a new product, new product format or an innovative solution that can answer to the rapidly changing preferences of the consumers. When it comes to identifying customer’s needs, industry trends and market movements, we are far ahead among our peers. With over 3 decades of presence, ours customers is are always at the center of all our activities. Our marketing team is backed by our impeccable research and development team who collaborate with the customers and provide them products and solutions that meets meet their expectations and are sustainable.
`,
};

export default MarketIng;
