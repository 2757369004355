import React from "react";
import Heading from "../../common/Heading";
// image
import fd1 from "../../../assets/images/home/food-tech-01.png";
import fd2 from "../../../assets/images/home/food-tech-02.png";
import { useNavigate } from "react-router-dom";
import useAOS from "../../../hooks/useAOS";

function News() {
  let navigate = useNavigate();
  useAOS();
  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }
  return (
    <div className="slider-container mt-10 pr-5 pl-5 tab950:pr-32  tab950:pl-32 gap-5 flex flex-col">
      <Heading
        heading={"FoodTech Trends"}
        upperText={"INDUSTRY NEWS"}
      ></Heading>
      <div className="grid grid-cols-1 tab650:grid-cols-2 gap-4">
        {news.map((card) => (
          <div
            onClick={() => navigate(`/show/blog/${makeHash(card.title)}`)}
            className={
              "flex flex-col gap-3 p-4 cursor-pointer " + card.className || ""
            }
          >
            <div data-aos="fade-right" className="card-img">
              <img src={card.img} alt="fd1" />
            </div>
            <div data-aos="fade-left" className="card-content flex flex-col gap-1 border-l border-[#83d7fb8d] pl-4 pr-4 ">
              <span className="text-2xl font-normal">{card.title}</span>
              <span className="text-[12px]">{card.content}</span>
            </div>
            <div data-aos="fade-right" className="card-btn pl-4 pr-4">
              <button className="border border-[#7CD3F7] p-2 pr-7 font-medium pl-7 text-[11px]">
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

let news = [
  {
    title: "The Brass Ring Clean Label",
    img: fd1,
    content:
      "The Clean Label trend is sweeping through the marketplace. Breakfast or late-night indulgences, sweets or savories, every brand, be it big or small, is opting to clean up their ingredient lists. Clean Label means a new product out of very few recognizable ingredients that consumers might use often at home. It promotes the prevention of artificial ingredients or synthetic chemicals.",
  },
  {
    className: "tab650:pt-32",
    title: "Millennial Food Preferences",
    img: fd2,
    content:
      "Millennials are the largest living generation with more spending power and a major role to play in determining the pop culture and trends. They are also known as the food generation. When they set trends, businesses quickly shift their attention towards the millennial culture. These young minds have distinct eating habits that the media and researchers analyze and follow.",
  },
];

export default News;
