import React from 'react'
import Heading from '../../common/Heading'
import myVideo from '../../../assets/video/video.mp4'
function BrandVideo() {
  return (
    <div className='p-3 tab950:p-8 tab950:pr-32  tab950:pl-32 mt-10  flex flex-col gap-4 bg-gray-100'>
        <Heading upperText = {"CREATIONS"} heading = {"Witness our food trip"} ></Heading>
        <div className='p-5'>
            <video controls >
                <source src={myVideo} type="video/mp4"/>
            </video>
        </div>
    </div>
  )
}

export default BrandVideo