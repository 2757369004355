import p1 from "../../../assets/images/products/beetroot-powder.jpg";
import p2 from "../../../assets/images/products/caseinates.jpg";
import p3 from "../../../assets/images/products/gum-arabic.jpg";
// fruits
import mango from "../../../assets/images/products/details/mango.png";
import apple from "../../../assets/images/products/details/apple.png";
import banana from "../../../assets/images/products/details/banana.png";
import orange from "../../../assets/images/products/details/orange.png"
import papaya from "../../../assets/images/products/details/papaya.png"
import lime from "../../../assets/images/products/details/lime_card.png"
import pineapple from "../../../assets/images/products/details/pineapple.png"
import strawberry from "../../../assets/images/products/details/strawberry.png"
export const productListing = [
  {
    img: p1,
    title: "Natural Color",
    color: "bg-[#7CBBE599]",
    link: "/details",
  },
  {
    img: p2,
    title: "Caseinates",
    color: "bg-[#85CCA199]",
    link: "/details",
  },
  {
    img: p3,
    title: "Spray Aragum",
    color: "bg-[#F6ADB999]",
    link: "/details",
  },
];

export const fruits = [
  {
    img: mango,
    content:
      "At Drytech, we produce Mango Powder from fresh Totapuri variety of mangoes having rich aromatic flavor perfectly blended with sweetness and acidity. It has perfect dispersibility and can be used in beverage and dessert premixes.",
    name: "Mango",
  },
  {
    img: apple,
    content:
      "Drytech’s Apple Powder adds to natural taste, aroma and flavor of the product. It resembles typical fresh red apple with its characteristic flavor and sweet taste. This property enables manufacturers to use apple powder in beverage premixes, nutraceutical products, baby or weaning foods.",
    name: "Apple",
  },
  {
    img: banana,
    content:
      "At Drytech, we provide authentic Banana Powder which has a natural and typical sweet ripe banana taste profile. Our banana powder can be used in beverages, bakery, baby or weaning foods.",
    name: "Banana",
  },
  {
    img: orange,
    content:
      "Drytech’s Orange Powder has a zesty orange flavor profile. It is easily dispersible in water and is easy to use. Our Orange Powder can be used in the industry segments that deals with convenience food like instant beverage, desserts and Ice-cream premixes.",
    name: "Orange",
  },
  {
    img: pineapple ,
    content:
      "Drytech’s Pineapple Powder is a balance of vitamin A and C with a characteristic pineapple flavor. Our natural Pineapple Powder can be used in the industry segments that deals with convenience food, cakes & dessert premixes, flavored creams, instant beverages & Ice-cream premixes without losing taste and flavor of the real fruit.",
    name: "Pineapple",
  },
  {
    img: strawberry,
    content:
      "Our natural strawberry powder possess the natural nutrients and taste. It provides the convenience to use in instant beverage, bakery and ice- cream premixes. The entire process begins from selection of best quality strawberries to pass on the maximum nutrient benefits to end product. The spray drying process is carefully designed to maintain the purity of strawberries intact.",
    name: "Strawberry",
  },
  {
    img: lime,
    content:
      "Drytech’s Lime powder has a taste of natural lime with typical sour and zesty flavor. Lime powder when added to beverages at the levels of 5-10% eliminates the usage of lime flavor in it. Our Lime powder can be used in the industry segments that deals with convenience foods like instant beverages and Ice-cream premixes.",
    name: "Lime",
  },
  {
    img: papaya,
    content:
      "Drytech’s Papaya Powder has a typical sweet papaya taste and all essential natural nutrients of real fruit. Our Papaya Powder can be used in beverages, nutraceutical products, baby or weaning foods as well as Ayurveda formulations.",
    name: "Papaya",
  },
];
